import { createStackNavigator } from "@react-navigation/stack";

import MyHospitalStayScreen from "../screens/MyHospitalStayScreen";
import defaultScreenOptions from "./DefaultOptions";
import Day0Screen from "../screens/Day0Screen";
import LookingAfterYourChestScreen from "../screens/LookingAfterYourChestScreen";
import StayingActiveInHospitalScreen from "../screens/StayingActiveInHospitalScreen";
import PsychologicalWellbeingScreen from "../screens/PsychologicalWellbeingScreen";
import DietNutritionHospitalStayScreen from "../screens/DietNutritionHospitalStayScreen";
import PotentialSideEffectsScreen from "../screens/PotentialSideEffectsScreen";

const Stack = createStackNavigator();

export default function HospitalStayStack() {
  return (
    <Stack.Navigator
      initialRouteName="HospitalStayScreen"
      screenOptions={{ headerShown: false }}
    >
      <Stack.Screen name="HospitalStayScreen" component={MyHospitalStayScreen} />
      <Stack.Screen
        name="Day0"
        component={Day0Screen}
        options={defaultScreenOptions}
      />
      <Stack.Screen
        name="StayingActiveInHospital"
        component={StayingActiveInHospitalScreen}
        options={defaultScreenOptions}
      />
      <Stack.Screen
        name="LookingAfterYourChest"
        component={LookingAfterYourChestScreen}
        options={defaultScreenOptions}
      />
      <Stack.Screen
        name="PsychologicalWellbeing"
        component={PsychologicalWellbeingScreen}
        options={defaultScreenOptions}
      />
      <Stack.Screen
        name="DietNutritionHospitalStay"
        component={DietNutritionHospitalStayScreen}
        options={defaultScreenOptions}
      />
      <Stack.Screen
        name="PotentialSideEffects"
        component={PotentialSideEffectsScreen}
        options={defaultScreenOptions}
      />
    </Stack.Navigator>
  );
}
