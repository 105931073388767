import React from "react";
import { Text } from "native-base";
import { DateTime } from "luxon";
import { LineChart, Legend, Line, CartesianGrid, XAxis, YAxis } from "recharts";
import RecordLineChart from "./RecordLineChart";

class LastReadingText extends React.Component
{
	render() {
		return (
			<>
				<Text fontWeight="bold">Last reading: </Text>
				{Object.keys(this.props.lastEntry).map((key) => {
					if (key !== "millis") {
						return (
							<Text key={key}>
								{key}: {" " + this.props.lastEntry[key]}
							</Text>
						)
					} else 
						return <React.Fragment key={key} />
					
				})}
				<Text color="#A3A3A3">
					(recorded {DateTime.fromMillis(this.props.lastEntry['millis']).toRelativeCalendar()})
				</Text>
			</>
		)
	}
}

export default class StackedRecordLineChart extends RecordLineChart
{
	updateChartData()
	{
		if(!Array.isArray(this.props.types))
			throw new Error("Expected an array of record types");
		
		Promise
			.all(this.props.types.map(type => this.getRecords(type)))
			.then(results => {

				if(this.props.normalize)
				{
					let scales, max = 0;

					results.forEach(records => {

						records.max = 0;

						records.forEach(record => {

							records.max = Math.max(records.max, record.points);
							max = Math.max(max, record.points);

						});

					});

					results.forEach(records => {

						records.scale = max / records.max;

					});
				}

				const data = [];
				const numParallelRecords = results[0].length;

				for(let recordIndex = 0; recordIndex < numParallelRecords; recordIndex++)
				{
					const datetime = DateTime.fromJSDate(results[0][recordIndex].timestamp);
					const obj = {
						millis: datetime.toMillis()
					};

					for(let typeIndex = 0; typeIndex < results.length; typeIndex++)
					{
						const record = results[typeIndex][recordIndex];
						let points = record.points;

						if(this.props.normalize)
							points *= results[typeIndex].scale;

						// TODO: Support labels via props
						obj[record.type] = points;
					}

					data.push(obj);
				}

				this.setState({
					chartData: data
				});

			});
	}

	render()
	{
		// const { type } = this.props;
		// data={this.state.chartData} 
		// units={RecordTypeUnits[type]}

		const colours = [
			"red",
			"blue",
			"green"
		];

		// TODO: Repeated from LineChart (native), should export this from somewhere instead
		const renderTimestampTick = ({ x, y, payload }) => {
			const datetime = DateTime.fromMillis(payload.value);
	
			return <svg x={x - 4} y={y} width={96} height={96} viewBox="0 0 512 512" fill="#666">
				 <style>
					{ `.small { font: italic 48px sans-serif; }` }
				</style>
				<text 
					x="0" 
					y="0" 
					className="small"
					textAnchor="top left"
					transform="rotate(60)"
					>
					{datetime.toLocaleString({month: "short", day: "numeric"})}
				</text>
			</svg>;
		};

		// TODO: This is a repetition of our native LineChart. Would be better to support children on that component, then refactor everywhere it's used. This would DRY up a bit.
		return <>
			<Text fontSize="md" style={{ color: "#73BD89" }}>
				{this.props.caption}
			</Text>
			{this.state.chartData.length >= 2 && (
				<LineChart
					width={300}
					height={200}
					data={this.state.chartData}
					margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
				>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis
						dataKey="millis"
						scale="time"
						tick={renderTimestampTick}
					/>
					<Legend />
					{
						this.props.types.map((type, index) => {
							return <Line
								type="monotone"
								key={type}
								dataKey={type}
								stroke={colours[index % colours.length]}
							/>;
						})
					}
				</LineChart>
			)}
			{this.state.chartData.length >= 1 && (
				<LastReadingText lastEntry={this.state.chartData[this.state.chartData.length - 1]} />
			)}
			{this.state.chartData.length === 0 && (
				<Text>Please enter some readings to get a graph.</Text>
			)}
		</>;
	}
}