import React from "react";
import { Text, Radio, HStack } from "native-base";

export default class QuestionnaireSlider extends React.Component
{
	render()
	{
		const friendlyAnswerDescription = this.props.friendlyAnswerDescriptions[this.props.value];

		return <>
			<Radio.Group onChange={value => this.props.onChange(value)}>	
				<HStack style={{width: "100%"}} justifyContent="space-between">
					{this.props.values.map(value => {
						return <Radio key={value} value={value} my={1}></Radio>;
					})}
				</HStack>
				<Text className="questionnaire-slider-friendly-value">{friendlyAnswerDescription}</Text>
			</Radio.Group>
		</>;
	}
}