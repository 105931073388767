import React from "react";
import {
	Box,
	Heading,
	VStack,
	Button,
	ScrollView,
	Center,
	FormControl,
	Input
} from "native-base";
import {
	StyleSheet,
	Text,
	View,
	TouchableHighlight,
} from "react-native";
import { Duration } from "luxon";
import Container from "./Container";

import { RECORD_TYPE_1_MIN_STS } from "../database/RecordType";
import RecordLineChart from "../components/RecordLineChart";
import WithTimer from "./WithTimer";

export default class STSScreen extends WithTimer
{
	get recordType()
	{
		return RECORD_TYPE_1_MIN_STS;
	}

	render()
	{
		const duration	= Duration.fromMillis(60 * 1000 - this.state.elapsed);
		const clockText	= duration.toFormat("hh:mm:ss:SSS");

		return <Container>
			<Heading ml="2" size="md" fontWeight="medium" color="coolGray.800">
				1 min
			</Heading>
			<Heading
				ml="2"
				mt="1"
				mb="4"
				color="coolGray.600"
				fontWeight="600"
				size="lg"
			>
				Sit to stand
			</Heading>
			<ScrollView>
				<Center>
					<VStack width="90%" mx="3" maxW="300px" alignItems="center">
						<Center>
							<Heading size="xs" fontWeight="500" ml={-0.5} mb={2} mt={2}>
								Complete as many full sit to stands from the chair as you can in 1 minute.
							</Heading>
							<Center>
								<View style={styles.sectionStyle}>
									<Box>
										<Text>
											{clockText}
										</Text>
									</Box>
									<TouchableHighlight
										onPress={() => { return this.state.isTimerRunning ? this.onStop() : this.onStart() }}
									>
										<Text style={styles.buttonStart}>
											{!this.state.isTimerRunning ? "START" : "STOP"}
										</Text>
									</TouchableHighlight>
									<TouchableHighlight
										onPress={() => this.onReset()}
									>
										<Text style={styles.buttonReset}>RESET</Text>
									</TouchableHighlight>
								</View>
								{
									this.state.elapsed > 0 && !this.state.isTimerRunning && <>
										<FormControl>
											<FormControl.Label>Repetitions</FormControl.Label>
											<Input
												type="number"
												placeholder="Repetitions"
												aria-label="Repetitions"
												onChangeText={(value) =>
													this.setState({
														value: value
													})
												}
											/>
										</FormControl>
										<Button
											disabled={isNaN(this.state.value)}
											onPress={() => this.onSubmit()}
											bg="brand.bmtBlue"
											_text={{
												color: "white",
											}}
											mt="4"
											p="4"
											rounded="8"
											shadow={2}
										>
											Submit
										</Button>
									</>
								}
							</Center>
						</Center>
					</VStack>
					<Box width="100%" mt="4">
						<RecordLineChart type={RECORD_TYPE_1_MIN_STS}/>
					</Box>
				</Center>
			</ScrollView>
		</Container>;
	}
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		padding: 10,
		justifyContent: "center",
		alignItems: "center",
	},
	sectionStyle: {
		flex: 1,
		marginTop: 32,
		alignItems: "center",
		justifyContent: "center",
	},
	buttonStart: {
		fontSize: 20,
		marginTop: 20,
        padding: 10,
		borderRadius: 30,
		backgroundColor: "#73BD89",
        color: "white",
	},
	buttonReset: {
		fontSize: 20,
		marginTop: 10,
        borderRadius: 30,
        padding: 10,
        backgroundColor: "#FF6B6B",
        color: "white",
	},
});

const options = {
	container: {
		padding: 5,
		borderRadius: 10,
		borderWidth: 5,
		width: 300,
		alignItems: "center",
	},
	text: {
		fontSize: 30,
		marginLeft: 7,
	},
};