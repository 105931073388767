import * as React from "react";
import {
	ScrollView,
	Center,
} from "native-base";

import ClickableCard from "../components/ClickableCard";
import ProgressOverview from "../components/ProgressOverview";
import Container from "./Container";
import UserContext from "../contexts/UserContext";
import db from "../database/database";
import { useFocusEffect } from "@react-navigation/native";

const MyProgressScreen = ({ navigation }) => {
	const [recordTypes, setRecordTypes] = React.useState([]);
	const { user } = React.useContext(UserContext);

	useFocusEffect(
		React.useCallback(() => {
			updateProgressData();
			return () => {}
		}, [user])
	);

	const updateProgressData = () => {
		if (user) {
			const results = {};
			db
				.records
				.where("idusers")
				.equals(user.id)
				.each((record) => {
					// count each unique record
					results[record.type] = (results[record.type] || 0) + 1;
				}).then(() => {
					const filteredRecords = Object.keys(results).filter((key) => results[key] > 2);
					setRecordTypes(filteredRecords);
				}).catch((error) => console.error(error));
		}
	}

	return <Container heading="My" subheading="Progress">
		<Center height="80vh">
			{!!recordTypes.length && (
				<ScrollView height="1/2">
					<ProgressOverview recordTypes={recordTypes} />
				</ScrollView>
			)}
			<ScrollView mt="4">
				<ClickableCard
					forwardLink={() => navigation.navigate("HowToMonitorProgress")}
					color={"#73BD89"}
					title={"How to monitor \nmy progress"}
					imageSource={require("../assets/images/information.jpeg")}
				/>
				<ClickableCard
					forwardLink={() => navigation.navigate("Bloods")}
					color={"#D11444"}
					title={"My\nBloods"}
					imageSource={require("../assets/images/prehab.jpg")}
				/>
				<ClickableCard
					forwardLink={() =>
						navigation.navigate("PostTransplantMonitoringStack")
					}
					color={"#4D4D45"}
					title={"Post-transplant\nmonitoring\n(2 weekly)"}
					imageSource={require("../assets/images/activity.jpg")}
				/>
				<ClickableCard
					forwardLink={() =>
						navigation.navigate("PhysicalActivityMonitoring")
					}
					color={"#1B979A"}
					title={"My physical\nactivity\nmonitoring\ntools"}
					imageSource={require("../assets/images/activity.jpg")}
				/>
			</ScrollView>
		</Center>
	</Container>;
};

export default MyProgressScreen;