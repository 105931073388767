import * as React from "react";
import {
	Box,
	Heading,
	VStack,
	ScrollView,
	Center,
} from "native-base";
import Container from "./Container";
import EmbeddedDocumentModal from "../components/EmbeddedDocumentModal";

const WhatIsPrehabScreen = ({ navigation }) => {
	return <Container heading="What is" subheading="Prehab?">
		<ScrollView>
			<Center>
				<EmbeddedDocumentModal
					title="What is prehab?"
					url="https://docs.google.com/document/d/e/2PACX-1vTh8_dczv4BB16ox_UXjZglwuhO07SyFnfLRv17lZSHcEDASGYN1LkvHpbkR8ZoMA/pub"
				/>
				<EmbeddedDocumentModal
					title="BMT in South Wales - Prehab Team"
					url="https://docs.google.com/document/d/e/2PACX-1vSOqgPOuKfH1Lod3N3c6Nr8vg7h19LAVERo0kDS1xrBAklu4Rhx_sP5nXP6nW97KQ/pub"
				/>
				<EmbeddedDocumentModal
					title="How to balance your day"
					url="https://docs.google.com/document/d/e/2PACX-1vTe3jGTpZeDQx4CfxtKRxv-_OGj-8JBhwuUMR6TlJhgGVOwFpXIZIUa2_FYsFtaaA/pub"
				/>
			</Center>
		</ScrollView>
	</Container>;
};

export default WhatIsPrehabScreen;
