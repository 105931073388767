import db from "./database/database";
import * as Crypto from 'expo-crypto';

import UserNotFoundError from "./exceptions/UserNotFoundError";
import IncorrectPasswordError from "./exceptions/IncorrectPasswordError";
import UserAlreadyExistsError from "./exceptions/UserAlreadyExistsError";

export default class User
{
	constructor(id=null, reset=false)
	{
		this.id = id;
		this.reset = reset;
	}

	get isLoggedIn()
	{
		return this.id != null;
	}

	hash = (string) => Crypto.digestStringAsync(Crypto.CryptoDigestAlgorithm.SHA512, string);

	async login(email, password)
	{
		const foundUser = await this.findUserWithEmail(email);
		if (!foundUser)
			throw new UserNotFoundError();
	
		const hashedPassword = await this.hash(foundUser.salt + password)
		if(hashedPassword !== foundUser.password)
			throw new IncorrectPasswordError();

		return foundUser;

	  }

	async create(email, password)
	{
		// check if user already exists
		const emailSearch = await this.findUserWithEmail(email)
		if (emailSearch)
			throw new UserAlreadyExistsError();

		const byteArray = new Uint8Array(16);
		const salt = Crypto.getRandomValues(byteArray);

		const hashedEmail = await this.hash(salt + email);
		const hashedPassword = await this.hash(salt + password);

		return db
			.users
			.add({
				email: hashedEmail,
				password: hashedPassword,
				salt,
			});
	}
	
	async findUserWithEmail(email) {
		const usersArray = await db.users.toArray()
		// cannot use array.filter here as we need to use the async hash function
		let foundUser = null;
		for (const user of usersArray) {
			if (await this.hash(user.salt + email) === user.email) {
				foundUser = user;
				break; 
			}
		}
		return foundUser;
	}
}