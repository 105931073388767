import db from "./database";
import {
	RECORD_TYPE_WEIGHT,
	RECORD_TYPE_WHITE_BLOOD_CELLS,
	RECORD_TYPE_HAEMOGLOBIN,
	RECORD_TYPE_PLATELETS,
	RECORD_TYPE_NEUTROPHILS,
	RECORD_TYPE_FATIGUE,
	RECORD_TYPE_ACTIVITY_LEVEL,
	RECORD_TYPE_SPPB,
	RECORD_TYPE_GODIN,
	RECORD_TYPE_1_MIN_STS,
	RECORD_TYPE_FACIT_F,
	RECORD_TYPE_EQ5D5L,
	RECORD_TYPE_HEALTH_STATE_VISUAL_SCALE,
	RECORD_TYPE_SIX_MINUTE_WALK_TEST_TIME_ELAPSED,
	RECORD_TYPE_SIX_MINUTE_WALK_TEST_DISTANCE_TRAVELLED
} from "./RecordType";

export default class Faker {
	generate(idusers) {
		const types = [
			RECORD_TYPE_WEIGHT,
			RECORD_TYPE_WHITE_BLOOD_CELLS,
			RECORD_TYPE_HAEMOGLOBIN,
			RECORD_TYPE_PLATELETS,
			RECORD_TYPE_NEUTROPHILS,
			RECORD_TYPE_FATIGUE,
			RECORD_TYPE_ACTIVITY_LEVEL,
			RECORD_TYPE_SPPB,
			RECORD_TYPE_GODIN,
			RECORD_TYPE_1_MIN_STS,
			RECORD_TYPE_FACIT_F,
			RECORD_TYPE_EQ5D5L,
			RECORD_TYPE_HEALTH_STATE_VISUAL_SCALE,
			RECORD_TYPE_SIX_MINUTE_WALK_TEST_TIME_ELAPSED,
			RECORD_TYPE_SIX_MINUTE_WALK_TEST_DISTANCE_TRAVELLED
		];

		const bounds = {
			[RECORD_TYPE_WEIGHT]: {
				min:	44,
				max:	107
			},
			[RECORD_TYPE_WHITE_BLOOD_CELLS]: {
				min:	0,
				max:	100
			},
			[RECORD_TYPE_HAEMOGLOBIN]: {
				min:	0,
				max:	100
			},
			[RECORD_TYPE_PLATELETS]: {
				min:	0,
				max:	100
			},
			[RECORD_TYPE_NEUTROPHILS]: {
				min:	0,
				max:	100
			},
			[RECORD_TYPE_FATIGUE]: {
				min:	0,
				max:	100
			},
			[RECORD_TYPE_ACTIVITY_LEVEL]: {
				min:	0,
				max:	100
			},
			[RECORD_TYPE_SPPB]: {
				min:	0,
				max:	100
			},
			[RECORD_TYPE_GODIN]: {
				min:	0,
				max:	100
			},
			[RECORD_TYPE_1_MIN_STS]: {
				min:	5,
				max:	30
			},
			[RECORD_TYPE_FACIT_F]: {
				min:	0,
				max:	52
			},
			[RECORD_TYPE_EQ5D5L]: {
				min:	0,
				max:	100
			},
			[RECORD_TYPE_HEALTH_STATE_VISUAL_SCALE]: {
				min:	0,
				max:	100
			},
			[RECORD_TYPE_SIX_MINUTE_WALK_TEST_TIME_ELAPSED]: {
				min:	0,
				max:	60 * 6
			},
			[RECORD_TYPE_SIX_MINUTE_WALK_TEST_DISTANCE_TRAVELLED]: {
				min:	0,
				max:	1000
			}
		}

		const now = new Date();
		const dates = {
			end: now
		};
		const promises = [];

		dates.start = new Date( now.getTime() - 1000 * 60 * 60 * 24 * 28 * 6 );

		for(const type of types)
		{
			let count = 0;

			for(
				let iter = dates.start.getTime();
				iter < dates.end.getTime();
				iter += (1 + Math.random() * 6) * 1000 * 60 * 60 * 24
				)
			{
				const range = bounds[type];
				const points = range.min + Math.round(Math.random() * (range.max - range.min));

				promises.push(
					db
						.records
						.add({
							type:		type,
							points:		points,
							timestamp:	new Date( iter ),
							idusers:	idusers
						})
				);

				count++;
			}
		}

		Promise.all(promises).then(() => console.log("Fake data generated"));
	}
}