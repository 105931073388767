import * as React from "react";
import STS from "../components/SPPB/STS";
import WithRecordsAndChart from "./WithRecordsAndChart";
import Explainer from "../components/SPPB/Explainer";
import Exercise from "../components/SPPB/Exercise";
import Score from "../components/SPPB/Score";
import { RECORD_TYPE_SPPB } from "../database/RecordType";
import db from "../database/database";

export default class SPPBScreen extends WithRecordsAndChart
{
	constructor()
	{
		super();

		this.stageDefinitions = [
			{
				component: Explainer
			},
			{
				component: Exercise,
				props: {
					subheading: "Feet Together",
					description: "Feet together side-by-side for 10 seconds",
					image: require("../assets/images/feet_together.f1e0ac03.png"),
					skipToStageIfUnderTenSeconds: 4,
					getPointsFromMilliseconds: milliseconds => {

						return milliseconds >= 10 * 1000 ? 1 : 0;

					}
				}
			},
			{
				component: Exercise,
				props: {
					subheading: "Semi tandem",
					description: "Heel of one foot against the side of big toe of the other for 10 seconds",
					image: require("../assets/images/semi_tandem.dfb0bc4c.png"),
					skipToStageIfUnderTenSeconds: 4,
					getPointsFromMilliseconds: milliseconds => {

						return milliseconds >= 10 * 1000 ? 1 : 0;

					}
				}
			},
			{
				component: Exercise,
				props: {
					subheading: "Full tandem",
					description: "Feet aligned heel to toe for 10 seconds",
					image: require("../assets/images/full_tandem.7e01c5cd.png"),
					skipToStageIfUnderTenSeconds: 4,
					getPointsFromMilliseconds: milliseconds => {

						if(milliseconds >= 10 * 1000)
							return 2;
						
						if(milliseconds >= 3 * 1000)
							return 1;
						
						return 0;

					}
				}
			},
			{
				component: Exercise,
				props: {
					subheading: "Gait Speed Test",
					description: "Measures the time required to walk 4 meters at a normal pace",
					image: require("../assets/images/gait-test.png"),
					getPointsFromMilliseconds: milliseconds => {

						if(milliseconds < 4.82 * 1000)
							return 4;
						
						if(milliseconds <= 6.20 * 1000)
							return 3;
						
						if(milliseconds <= 8.7 * 1000)
							return 2;
						
						if(milliseconds > 0)
							return 1;
						
						return 0;

					}
				}
			},
			{
				component: STS
			},
			{
				component: Score
			}
		]

		this.state = {
			points: 0,
			currentStageIndex: 0
		};
	}

	get recordType()
	{
		return RECORD_TYPE_SPPB;
	}

	componentDidMount() {
		const { navigation } = this.props;
		this.focusListener = navigation.addListener("focus", () => this.reset());
	}

	componentWillUnmount() {
		this.props.navigation.removeListener(this.focusListener);
	}
	
	reset()
	{
		this.setState({
			points: 0,
			currentStageIndex: 0
		});
	}

	onProceed(args)
	{
		const user				= this.context.user;
		let nextStageIndex		= this.state.currentStageIndex + 1;
		let points				= this.state.points;

		if(args && "points" in args)
			points += args.points;
		
		if(args && "skipToStageIndex" in args)
			nextStageIndex = args.skipToStageIndex;

		if(nextStageIndex >= this.stageDefinitions.length - 1)
		{
			db
				.records
				.add({
					type:		this.recordType,
					points:		points,
					timestamp:	new Date(),
					idusers:	user.id
				})
				.then(() => this.forceUpdate());
		}

		this.setState({
			points: points,
			currentStageIndex: nextStageIndex
		});
	}

	render()
	{
		console.log(this.state.points);

		const stages = this.stageDefinitions.map((definition, index) => {

			const props = {
				...definition.props,
				key:		index,
				points:		this.state.points,
				navigation:	this.props.navigation,
				onProceed:	data => this.onProceed(data)
			};

			return <definition.component {...props}/>;

		})

		return <>{stages[this.state.currentStageIndex]}</>;
	}
}