import * as React from "react";
import {
	Box,
	Heading,
	VStack,
	ScrollView,
	Center,
} from "native-base";
import Container from "./Container";
import EmbeddedDocumentModal from "../components/EmbeddedDocumentModal";

const ReturningToActivitiesScreen = ({ navigation }) => {
	return <Container heading="Returning to" subheading="Meaningful Activities">
		<ScrollView>
			<Center>
				<EmbeddedDocumentModal
					title="Employment and Education"
					url="https://docs.google.com/document/d/e/2PACX-1vQBpthsGhIIIA3G8FVJpwARKIslglWxmJKVCgD9jxFb9R1kSNyJB-zg880xm9w8pA/pub"
				/>
				<EmbeddedDocumentModal
					title="Psychological Wellbeing after discharge"
					url="https://docs.google.com/document/d/e/2PACX-1vQg4qiNFVOoHyLf69C_GGw8gS90ahh00M6ihKT2uvwhaTMXsdm_pk9rx-0oGebDyA/pub"
				/>
			</Center>
		</ScrollView>
	</Container>;
};

export default ReturningToActivitiesScreen;
