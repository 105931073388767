import * as React from "react";
import {
	Box,
	Heading,
	VStack,
	ScrollView,
	Center,
} from "native-base";
import Container from "./Container";
import EmbeddedDocumentModal from "../components/EmbeddedDocumentModal";

const PsychologicalWellbeingScreen = ({ navigation }) => {
	return <Container heading="Psychological" subheading="Wellbeing">
		<ScrollView>
			<Center>
				<EmbeddedDocumentModal
					title="During Hospital treatment"
					url="https://docs.google.com/document/d/e/2PACX-1vRGSPCdR64pYPLiDSqc3TthueBF2b3dcv4r79DhyqPk6r_-Qmn6TmOTm0KWobCtGQ/pub"
				/>
				<EmbeddedDocumentModal
					title="Preparing for your inpatient stay"
					url="https://docs.google.com/document/d/e/2PACX-1vTpYvjHDwsbbCpiO8E87HCnVZRqiJGgzpMdAj0KjL9-g8jmsE7bPU5PpKBuF3FFKQ/pub"
				/>
				<EmbeddedDocumentModal
					title="Preparing for discharge"
					url="https://docs.google.com/document/d/e/2PACX-1vR_SvDAyBh2w8eViQ5DiDGkK2BkyJyz_a5wP9J8lvoXwbH_01MbW6ghNB--ka2kSg/pub"
				/>
			</Center>
		</ScrollView>
	</Container>;
};

export default PsychologicalWellbeingScreen;
