import React from "react";
import {
	Box,
	Text,
	Heading,
	VStack,
	Input,
	Button,
	HStack,
	ScrollView,
	Center,
    Stack,
    Divider,
} from "native-base";
import Container from "./Container";
import WithRecordsAndChart from "./WithRecordsAndChart";
import RecordLineChart from "../components/RecordLineChart";
import { RECORD_TYPE_GODIN } from "../database/RecordType";

const WEIGHT_STRENUOUS	= 9;
const WEIGHT_MODERATE	= 5;
const WEIGHT_LIGHT		= 3;

export default class GODINQuestionnnaireScreen extends WithRecordsAndChart
{
	constructor()
	{
		super();

		this.state = {
			strenuous:	0,
			moderate:	0,
			light:		0,
			value:		0
		}
	}

	get recordType()
	{
		return RECORD_TYPE_GODIN;
	}

	onChange(key, value)
	{
		const state = {...this.state};

		state[key] = value.replace(/[^0-9]/g, "");

		state.value = state.strenuous * WEIGHT_STRENUOUS
			+ state.moderate * WEIGHT_MODERATE
			+ state.light * WEIGHT_LIGHT;
		
		this.setState(state);
	}

	render()
	{
		return <Container>
			<Heading ml="2" size="md" fontWeight="medium" color="coolGray.800">
				GODIN
			</Heading>
			<Heading
				ml="2"
				mt="1"
				mb="4"
				color="coolGray.600"
				fontWeight="600"
				size="lg"
			>
				questionnaire
			</Heading>
			<ScrollView height="80vh">
				<Center>
					<VStack width="90%" mx="3" maxW="300px" alignItems="center">
						<Center>
							<Text italic mb={4} mt={2}>
								During a typical <Text bold>7-Day period</Text> (a week),
								how many times on the average do you do the following kinds
								of exercise for<Text bold> more than 15 minutes </Text>
								during your free time (write on each line the appropriate
								number).
							</Text>
							<Text italic mb={4} mt={2}>
								<Text bold>Weekly leisure activity score</Text> = (9 ×
								Strenuous) + (5 × Moderate) + (3 × Light)
							</Text>
							<VStack space={4} divider={<Divider />} maxW="300px">
								<Stack>
									<Divider mb={4} />
									<Text mb={2}>
										STRENUOUS EXERCISE
										<br />
										(HEART BEATS RAPIDLY)
									</Text>
									<Text fontSize="xs" mb={2}>
										(e.g., running, jogging, hockey, football, soccer,
										squash, basketball, cross country skiing, judo, roller
										skating, vigorous swimming, vigorous long distance
										bicycling)
									</Text>
									<HStack justifyContent="space-between">
										<Stack space={2}>
											<Input
												size="md"
												width="10"
												height="10"
												placeholder="nr"
												borderColor="brand.bmtGreen"
												keyboardType="numeric"
												value={this.state.strenuous}
												onChangeText={value => this.onChange("strenuous", value)}
											/>
											<Text fontSize="xs">
												times <br />
												per week
											</Text>
										</Stack>
										<Box
											p={2}
											borderWidth={1}
											textAlign="center"
											borderRadius={4}
											borderColor="#22D3EE"
											size="md"
											width="10"
											height="10"
										>
											x9
										</Box>
										<Stack space={2}>
											<Box
												p={2}
												borderWidth={1}
												textAlign="center"
												borderRadius={4}
												borderColor="brand.bmtDarkGreen"
												size="md"
												width="12"
												height="10"
											>
												{this.state.strenuous * WEIGHT_STRENUOUS}
											</Box>
											<Text fontSize="xs">Totals</Text>
										</Stack>
									</HStack>
								</Stack>
								<Stack>
									<Text mb={2}>
										MODERATE EXERCISE <br />
										(NOT EXHAUSTING)
									</Text>
									<Text fontSize="xs" mb={2}>
										(e.g., fast walking, baseball, tennis, easy bicycling,
										volleyball, badminton, easy swimming, alpine skiing,
										popular and folk dancing)
									</Text>
									<HStack justifyContent="space-between">
										<Stack space={2}>
											<Input
												size="md"
												width="10"
												height="10"
												placeholder="nr"
												borderColor="brand.bmtGreen"
												keyboardType="numeric"
												value={this.state.moderate}
												onChangeText={value => this.onChange("moderate", value)}
											/>
											<Text fontSize="xs">
												times <br />
												per week
											</Text>
										</Stack>
										<Box
											p={2}
											borderWidth={1}
											textAlign="center"
											borderRadius={4}
											borderColor="#22D3EE"
											size="md"
											width="10"
											height="10"
										>
											x5
										</Box>
										<Stack space={2}>
											<Box
												p={2}
												borderWidth={1}
												textAlign="center"
												borderRadius={4}
												borderColor="brand.bmtDarkGreen"
												size="md"
												width="12"
												height="10"
											>
												{this.state.moderate * WEIGHT_MODERATE}
											</Box>
											<Text fontSize="xs">Totals</Text>
										</Stack>
									</HStack>
								</Stack>
								<Stack>
									<Text mb={2}>
										MILD/LIGHT EXERCISE <br />
										(MINIMAL EFFORT)
									</Text>
									<Text fontSize="xs" mb={2}>
										(e.g., yoga, archery, fishing from river bank, bowling,
										horseshoes, golf, snow-mobiling, easy walking)
									</Text>
									<HStack justifyContent="space-between">
										<Stack space={2}>
											<Input
												size="md"
												width="10"
												height="10"
												placeholder="nr"
												borderColor="brand.bmtGreen"
												keyboardType="numeric"
												value={this.state.light}
												onChangeText={value => this.onChange("light", value)}
											/>
											<Text fontSize="xs">
												times <br />
												per week
											</Text>
										</Stack>
										<Box
											p={2}
											borderWidth={1}
											textAlign="center"
											borderRadius={4}
											borderColor="#22D3EE"
											size="md"
											width="10"
											height="10"
										>
											x3
										</Box>
										<Stack space={2}>
											<Box
												p={2}
												borderWidth={1}
												textAlign="center"
												borderRadius={4}
												borderColor="brand.bmtDarkGreen"
												size="md"
												width="12"
												height="10"
											>
												{this.state.light * WEIGHT_LIGHT}
											</Box>
											<Text fontSize="xs">Totals</Text>
										</Stack>
									</HStack>
									<Divider mt={4} mb={4} />
									<Stack space={2} alignItems="center">
										<Button colorScheme="success" onPress={event => this.onSubmit(event)}>
											Confirm inputs
										</Button>
										<Box
											p={2}
											textAlign="center"
											borderRadius={4}
											borderWidth={2}
											borderColor="brand.bmtGray"
											size="md"
											width="50px"
											height="10"
										>
											{
												this.state.value
											}
										</Box>
										<Text fontSize="xs">Total</Text>
									</Stack>
									<Divider mt={4} />
								</Stack>
							</VStack>
						</Center>
					</VStack>
					<Stack width="90%" mx="3" maxW="300px">
						<Heading size="xs" fontWeight="500" ml={-0.5} mb={2} mt={2}>
							Example
						</Heading>
						<Text italic>
							Strenuous = <Text bold>3</Text> times/wk
						</Text>
						<Text italic>
							Moderate = <Text bold>6</Text> times/wk
						</Text>
						<Text italic>
							Light = <Text bold>14</Text> times/wk
						</Text>
						<Text italic>
							<Text bold>Total leisure activity score</Text>= (9 ×{" "}
							<Text bold>3</Text>) + (5 × <Text bold>6</Text>) + (3 ×{" "}
							<Text bold>14</Text>) = 27 + 30 + 42 = 99
						</Text>
					</Stack>
					<Box width="100%" mt="4">
						<RecordLineChart type={this.recordType}/>
					</Box>
				</Center>
			</ScrollView>
		</Container>
	}
}
