import { createStackNavigator } from "@react-navigation/stack";
import FatigueScoreScreen from "../screens/FatigueScoreScreen";
import GODINQuestionnnaireScreen from "../screens/GODINQuestionnnaireScreen";
import defaultScreenOptions from "./DefaultOptions";
import PostTransplantMonitoringScreen from "../screens/PostTransplantMonitoringScreen";
import MyWeightScreen from "../screens/MyWeightScreen";


const Stack = createStackNavigator();

export default function PostTransplantMonitoringStack() {
  return (
    <Stack.Navigator
      initialRouteName="PostTransplantMonitoringScreen"
      screenOptions={{ headerShown: false }}
    >
      <Stack.Screen
        name="MyWeight"
        component={MyWeightScreen}
        options={defaultScreenOptions}
      />
      <Stack.Screen
        name="PostTransplantMonitoringScreen"
        component={PostTransplantMonitoringScreen}
      />
      <Stack.Screen
        name="FatigueScore"
        component={FatigueScoreScreen}
        options={defaultScreenOptions}
      />
      <Stack.Screen
        name="GODINQuestionnnaire"
        component={GODINQuestionnnaireScreen}
        options={defaultScreenOptions}
      />
    </Stack.Navigator>
  );
}
