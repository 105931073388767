import React from "react";

import {
	StyleSheet,
	Text,
	View,
	TouchableHighlight,
} from "react-native";

import {
	Box,
	Heading,
	VStack,
	Button,
	ScrollView,
	Center,
} from "native-base";

import { Duration } from "luxon";

const styles = StyleSheet.create({
	container: {
		flex: 1,
		padding: 10,
		justifyContent: "center",
		alignItems: "center",
	},
	sectionStyle: {
		flex: 1,
		marginTop: 32,
		alignItems: "center",
		justifyContent: "center",
	},
	buttonStart: {
		fontSize: 20,
		marginTop: 20,
        padding: 10,
		borderRadius: 30,
		backgroundColor: "#73BD89",
        color: "white",
	},
	buttonReset: {
		fontSize: 20,
		marginTop: 10,
        borderRadius: 30,
        padding: 10,
        backgroundColor: "#FF6B6B",
        color: "white",
	},
});

export default function Stopwatch({ elapsed, isTimerRunning, onStart, onStop, onReset }) {

	const duration	= Duration.fromMillis(elapsed);
	const clockText	= duration.toFormat("hh:mm:ss:SSS");

	return <View style={styles.sectionStyle}>
		<Box>
			<Text>
				{clockText}
			</Text>
		</Box>
		<TouchableHighlight
			onPress={() => { return isTimerRunning ? onStop() : onStart() }}
		>
			<Text style={styles.buttonStart}>
				{!isTimerRunning ? "START" : "STOP"}
			</Text>
		</TouchableHighlight>
		<TouchableHighlight
			onPress={() => onReset()}
		>
			<Text style={styles.buttonReset}>RESET</Text>
		</TouchableHighlight>
	</View>;

}