import * as React from "react";
import {
	Box,
	Heading,
	VStack,
	ScrollView,
	Center,
} from "native-base";

import Container from "./Container";
import EmbeddedDocumentModal from "../components/EmbeddedDocumentModal";

const FatigueManagementScreen = ({ navigation }) => {
	return <Container heading="Fatigue" subheading="Management">
		<ScrollView>
			<Center>

				<Heading
					mb="2"
					size="md"
					fontWeight="medium"
					color="coolGray.400"
				>
					General Information
				</Heading>

				<EmbeddedDocumentModal
					title="Boom &amp; Bust"
					url="https://docs.google.com/document/d/e/2PACX-1vSsXctvRZcDfHIYT_q9Ab6FU3a4XLsay48b1p6XSUs6dpc-vOS69vBPXJLjtkDvNQ/pub"
				/>
				<EmbeddedDocumentModal
					title="Useful links"
					url="https://docs.google.com/document/d/e/2PACX-1vTG-89runkYttRvGKnB0o-hFnFyxagWFXaq22pyDcatQ1f4kRXvC74Jx1Q_QIOmgw/pub"
				/>
				<EmbeddedDocumentModal
					title="Physical Activity and Fatigue Management"
					url="https://docs.google.com/document/d/e/2PACX-1vR0fbLkqcFbxn7xC73QrVNB0q0OrfooRoMutbIyJp4VTAxEgEpu8ogvs2008SYIzw/pub"
				/>

				<Heading
					mb="2"
					size="md"
					fontWeight="medium"
					color="coolGray.400"
				>
					Strategies &amp; guidance
				</Heading>

				<EmbeddedDocumentModal
					title="Energy conservation"
					url="https://docs.google.com/document/d/e/2PACX-1vRVkKQ8noMO35f1Jv3BL5nTxt2JCzVguqMDW0PDJjH6SiLskHIjZg-Q4Q8uTh-gWw/pub"
				/>
				<EmbeddedDocumentModal
					title="Goal Setting"
					url="https://docs.google.com/document/d/e/2PACX-1vQ-wqblqSXTd5_LKtI1pajH7YERdT3wwlIS7JBQF_OZQshPylEgwH5I5l2OAr8q4A/pub"
				/>

				<Heading
					mb="2"
					size="md"
					fontWeight="medium"
					color="coolGray.400"
				>
					Useful Videos
				</Heading>

				<EmbeddedDocumentModal
					title="Fatigue Management Video"
					url="https://docs.google.com/document/d/e/2PACX-1vR__XBoUnlhSk6R01PqDhu6u4GLKKbM04PFOq0SgpvG92P9iiE0MCETTxbsg7Us5g/pub"
				/>

			</Center>
		</ScrollView>
	</Container>;
};

export default FatigueManagementScreen;
