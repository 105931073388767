import React, { useContext } from "react";
import { View, Text, Image, TouchableOpacity } from "react-native";
import {
  DrawerContentScrollView,
  DrawerItemList,
} from "@react-navigation/drawer";
import { DrawerActions } from "@react-navigation/native";
import Ionicons from "react-native-vector-icons/Ionicons";
import { SafeAreaView } from "react-native-safe-area-context";

import UserContext from "../contexts/UserContext";
import User from "../User";

const CustomDrawer = (props) => {
  const { user, setUser } = useContext(UserContext);
  const handleLogOut = () => {
    props.navigation.dispatch(DrawerActions.closeDrawer());
    setUser(new User(null, true));
    // props.navigation.navigate("Login", { screen: "LoginScreen", reset: true });
  };

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: "#59a976" }}>
      <DrawerContentScrollView
        contentContainerStyle={{ backgroundColor: "#59a976" }}
      >
        <View style={{ padding: 20 }}>
          <Image
            source={require("../assets/images/bmt-logo__whiteCircle.png")}
            style={{
              height: 80,
              width: 80,
              borderRadius: 40,
              marginBottom: 10,
            }}
          />
          <Text
            style={{
              color: "#fff",
              fontSize: 24,
              fontFamily: "roboto-black",
              marginTop: 24,
            }}
          >
            Menu
          </Text>
        </View>
        <DrawerItemList {...props} />
      </DrawerContentScrollView>
      <View style={{ padding: 20, borderTopWidth: 1, borderTopColor: "#fff" }}>
      {/*  <TouchableOpacity onPress={() => {}} style={{ paddingVertical: 15 }}>
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Ionicons name="settings-outline" size={22} color={"white"} />
            <Text
              style={{
                fontSize: 15,
                color: "#fff",
                fontFamily: "roboto-regular",
                marginLeft: 5,
              }}
            >
              Settings
            </Text>
          </View>
            </TouchableOpacity>*/}
        <TouchableOpacity
          onPress={handleLogOut}
          style={{ paddingVertical: 15 }}
        >
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Ionicons name="exit-outline" size={22} color={"white"} />
            <Text
              style={{
                fontSize: 15,
                color: "#fff",
                fontFamily: "roboto-regular",
                marginLeft: 5,
              }}
            >
              Sign Out
            </Text>
          </View>
        </TouchableOpacity>
      </View>
    </SafeAreaView>
  );
};

export default CustomDrawer;
