import React from "react";
import { LineChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Line } from "recharts";
import { DateTime } from "luxon";
import LineChartTooltip from "./LineChartTooltip"

export default function NativeLineChart({ data, units }) {

	if(!Array.isArray(data))
		throw new Error("Expected data prop to be an array");
	
	if(!units)
		units = "points";

	const transformed = data.map(record => {
		const datetime = DateTime.fromJSDate(record.timestamp);

		return {
			"millis": datetime.toMillis(),
			"points": record.points
		};
	});

	const renderTimestampTick = ({ x, y, payload }) => {
		const datetime = DateTime.fromMillis(payload.value);

		return <svg x={x - 4} y={y} width={96} height={96} viewBox="0 0 512 512" fill="#666">
			 <style>
    			{ `.small { font: italic 48px sans-serif; }` }
			</style>
			<text 
				x="0" 
				y="0" 
				className="small"
				textAnchor="top left"
				transform="rotate(60)"
				>
				{datetime.toLocaleString({month: "short", day: "numeric"})}
			</text>
		</svg>;
	};

	return <LineChart 
		width={300} 
		height={200} 
		data={transformed}
		margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
		>
		<CartesianGrid strokeDasharray="3 3" />
		<XAxis 
			dataKey="millis" 
			scale="time"
			tick={renderTimestampTick}
		/>
		<YAxis />
		<Tooltip content={<LineChartTooltip units={units}/>}/>
		<Legend />
		<Line name={units} type="monotone" dataKey="points" stroke="#8884d8"/>
	</LineChart>;
}
