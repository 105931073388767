import * as React from "react";
import {
	Box,
	Heading,
	VStack,
	ScrollView,
	Center,
} from "native-base";
import Container from "./Container";
import EmbeddedDocumentModal from "../components/EmbeddedDocumentModal";

const GeneralAdmissionScreen = ({ navigation }) => {
	return <Container heading="General" subheading="Admission">
		<ScrollView height="80vh">
			<Center mb="4">
				<EmbeddedDocumentModal
					title="Preparing for Hospital Isolation"
					url="https://docs.google.com/document/d/e/2PACX-1vR6m-Ba5oWj-i9sXECcF_wpHTKTK6GizBpSW391pZ1ogP8x2-MnFaO6dSEIIGaICQ/pub"
				/>
				<EmbeddedDocumentModal
					title="What to bring with you"
					url="https://docs.google.com/document/d/e/2PACX-1vS06GLjKo7TXwns8qpa2wcxir0CFEns-MNdl4djG_b2cGtMtIyhPN_J6HovxzRXRA/pub"
				/>
				<EmbeddedDocumentModal
					title="Your admission"
					url="https://docs.google.com/document/d/e/2PACX-1vRp9ZWPfY_I0FBK0ELGnZY3x8Fv_44ShFhOiHo-3uUx079rAW03BcynuKtogE5EZg/pub"
				/>
				<EmbeddedDocumentModal
					title="How to get to the ward"
					url="https://docs.google.com/document/d/e/2PACX-1vS8hNJpm02M4LdG-2MWVVqA0G0Lsj3Fm-CEuGc9RMlwzbbkQRTC_vxJVdo-PzdKRw/pub"
				/>
				<EmbeddedDocumentModal
					title="The ward"
					url="https://docs.google.com/document/d/e/2PACX-1vSyFhAF4FbPa5F2wsyaPkcueOm8T8Wh92XxzJyn3pUsMtmzGl4wtEmMLt9XjNAT0Q/pub"
				/>
				<EmbeddedDocumentModal
					title="A typical day"
					url="https://docs.google.com/document/d/e/2PACX-1vQU5DLDOpXd_EhSCaFK47QOtYeHbhpYlvKBtReLKHC-qxVK46hSrLpwpjx7ljp9QA/pub"
				/>
				<EmbeddedDocumentModal
					title="Types of transplants"
					url="https://docs.google.com/document/d/e/2PACX-1vQQ_JdyVy17joN_NySPZFKHsOoWZeYj5x46mnTE3Zm4kmV3mo-qyUMcbbMXPFtU7A/pub"
				/>
				<EmbeddedDocumentModal
					title="The transplant - photos"
					url="https://docs.google.com/document/d/e/2PACX-1vQaV2fzcL0ZZiI9D5O_B66mDhaGF0cG9XgPCpZBMliLVnTXCzrb9kG2dswEY9DDjw/pub"
				/>
			</Center>
		</ScrollView>
	</Container>;
};

export default GeneralAdmissionScreen;
