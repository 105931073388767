import "react-native-gesture-handler";
import * as React from "react";
import { HamburgerIcon } from "native-base";


const NavigationDrawerStructure = (props) => {
	//Structure for the navigation Drawer
	const toggleDrawer = () => {
		//Props to open/close the drawer
		alert('Drawer clicked')
		props.navigationProps.toggleDrawer();
		//navigation.dispatch(DrawerActions.toggleDrawer());
	};

	return (
		<div style={{ flexDirection: "row" }}>
			<div onClick={toggleDrawer}>
				<HamburgerIcon
					size="6"
					style={{ width: 25, height: 25, marginRight: 16 }}
				/>
			</div>
		</div>
	);
}

export default NavigationDrawerStructure