import Dexie from "dexie";

const db = new Dexie("bmt_connect");

db.version(3).stores({
	records: "++id, type, points, timestamp, idusers",
	users: "++id, email, salt"
});

export default db;
