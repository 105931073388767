import * as React from "react";
import {
	Box,
	Text,
	Heading,
	VStack,
	ScrollView,
	Center,
    Pressable
} from "native-base";
import Container from "./Container";

const PreparingForTransplantScreen = ({ navigation }) => {
	return <Container heading="Preparing for my" subheading="Transplant">
		<ScrollView>
			<Center>
				<Pressable onPress={() => navigation.navigate("GeneralAdmissionScreen")}>
					{({ isHovered, isFocused, isPressed }) => {
						return (
							<Box
								width="311"
								mt="4"
								borderWidth="1"
								borderColor="coolGray.300"
								shadow="3"
								bg={
									isPressed
										? "brand.bmtDarkGreen"
										: isHovered
										? "brand.bmtDarkGreen"
										: "brand.bmtGreen"
								}
								p="5"
								rounded="8"
								style={{
									transform: [
										{
											scale: isPressed ? 0.96 : 1,
										},
									],
								}}
							>
								<Text color="#fff" fontWeight="medium" fontSize="xl">
									General Admission
								</Text>
							</Box>
						);
					}}
				</Pressable>
				<Pressable onPress={() => navigation.navigate("DietNutrition")}>
					{({ isHovered, isFocused, isPressed }) => {
						return (
							<Box
								width="311"
								mt="4"
								borderWidth="1"
								borderColor="coolGray.300"
								shadow="3"
								bg={
									isPressed
										? "brand.bmtDarkGreen"
										: isHovered
										? "brand.bmtDarkGreen"
										: "brand.bmtGreen"
								}
								p="5"
								rounded="8"
								style={{
									transform: [
										{
											scale: isPressed ? 0.96 : 1,
										},
									],
								}}
							>
								<Text color="#fff" fontWeight="medium" fontSize="xl">
									Diet &amp; Nutrition
								</Text>
							</Box>
						);
					}}
				</Pressable>
				<Pressable onPress={() => navigation.navigate("Prehab")}>
					{({ isHovered, isFocused, isPressed }) => {
						return (
							<Box
								width="311"
								mt="4"
								borderWidth="1"
								borderColor="coolGray.300"
								shadow="3"
								bg={
									isPressed
										? "brand.bmtDarkGreen"
										: isHovered
										? "brand.bmtDarkGreen"
										: "brand.bmtGreen"
								}
								p="5"
								rounded="8"
								style={{
									transform: [
										{
											scale: isPressed ? 0.96 : 1,
										},
									],
								}}
							>
								<Text color="#fff" fontWeight="medium" fontSize="xl">
									Prehabilitation
								</Text>
							</Box>
						);
					}}
				</Pressable>
				<Pressable
					onPress={() => navigation.navigate("AmbulatoryCare")}
				>
					{({ isHovered, isFocused, isPressed }) => {
						return (
							<Box
								width="311"
								mt="4"
								borderWidth="1"
								borderColor="coolGray.300"
								shadow="3"
								bg={
									isPressed
										? "brand.bmtDarkGreen"
										: isHovered
										? "brand.bmtDarkGreen"
										: "brand.bmtGreen"
								}
								p="5"
								rounded="8"
								style={{
									transform: [
										{
											scale: isPressed ? 0.96 : 1,
										},
									],
								}}
							>
								<Text color="#fff" fontWeight="medium" fontSize="xl">
									Ambulatory Care
								</Text>
							</Box>
						);
					}}
				</Pressable>
			</Center>
		</ScrollView>
	</Container>;
};

export default PreparingForTransplantScreen;
