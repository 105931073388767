import React, { useContext } from "react";

import { useNavigation } from "@react-navigation/native";
import {
  createDrawerNavigator,
  DrawerToggleButton,
} from "@react-navigation/drawer";

import DashboardScreen from "../screens/DashboardScreen";
import InformationStack from "./InformationStack";
import ProgressStack from "./ProgressStack";
import CommunityStack from "./CommunityStack";
import CustomDrawer from "../components/CustomDrawer";
import FirstScreenStack from "./LoginScreen";
import UserContext from "../contexts/UserContext";
import PhysicalActivityStack from "./PhysicalActivityStack";
import HeaderButtons from "../components/HeaderButtons";
import WhatIsPrehabScreen from "../screens/WhatIsPrehabScreen";

export default function DrawerNavigator() {
  const Drawer = createDrawerNavigator();
  const navigation = useNavigation();
  const { user } = useContext(UserContext);

  return (
    <Drawer.Navigator
      drawerContent={(props) => <CustomDrawer {...props} />}
      screenOptions={{
        drawerPosition: "right",
        drawerLabelStyle: { color: "#FFF" },
        headerShown: true,
        headerLeft: () => <HeaderButtons navigation={navigation} user={user} />,
        headerRight: () => <DrawerToggleButton />,
        headerMode: "screen",
        headerStyle: {
          backgroundColor: "#fff", //Set Header color
        },
        headerTintColor: "#4D4D45", //Set Header text color: ;
        headerTitleStyle: {
          fontWeight: "bold", //Set Header text style
        },
        drawerStyle: {
          backgroundColor: "#e6e6e6",
        },
      }}
    >
      {user.isLoggedIn ? (
        <>
          <Drawer.Screen
            name="Dashboard"
            component={DashboardScreen}
            options={{ drawerLabel: "Dashboard" }}
          />
          <Drawer.Screen
            name="InformationStack"
            options={{ drawerLabel: "Information", title: "Information" }}
            component={InformationStack}
          />
          <Drawer.Screen
            name="Prehab"
            component={WhatIsPrehabScreen}
            options={{ drawerLabel: "Prehab" }}
          />
          <Drawer.Screen
            name="PhysicalActivityStack"
            component={PhysicalActivityStack}
            options={{ drawerLabel: "Physical Activity" }}
          />
          <Drawer.Screen
            name="ProgressStack"
            component={ProgressStack}
            options={{ drawerLabel: "Progress", title: "Progress" }}
          />
          <Drawer.Screen
            name="CommunityStack"
            component={CommunityStack}
            options={{ drawerLabel: "Community", title: "Community" }}
          />
        </>
      ) : (
        <Drawer.Screen
          name="Login"
          options={{
            drawerLabel: "Login",
            drawerStyle: { display: "none" }, // prevent drawer flashing up on refresh
            headerShown: false,
          }}
          component={FirstScreenStack}
        />
      )}
    </Drawer.Navigator>
  );
}
