import React from "react";
import { DateTime } from "luxon";
import { Box } from "native-base";

export default function LineChartTooltip({ payload, label, active, units }) {
	if (active && payload) {

		// TODO: Why?! Weird way of accessing it. Consult the library docs.
		const record = payload[0].payload;
		const datetime = DateTime.fromMillis(record.millis);

		return (
			<Box backgroundColor="white" padding="1rem" shadow="5">
				<div className="custom-tooltip">
					<p className="date">
						{datetime.toLocaleString(DateTime.DATETIME_MED)}
					</p>
					<p className="points">
						{record.points} {units}
					</p>
				</div>
			</Box>
		);

	}

	return null;
}