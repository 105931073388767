import WithRecordsAndChart from "./WithRecordsAndChart";

export default class WithTimer extends WithRecordsAndChart
{
	constructor()
	{
		super();

		this.state = {
			isTimerRunning:	false,
			elapsed: 0
		};
	}

	componentDidMount()
	{
		this.mounted = true;
		requestAnimationFrame(() => this.onAnimationFrame());
	}

	componentWillUnmount()
	{
		this.mounted = false;
	}

	onAnimationFrame()
	{
		if(!this.mounted)
			return;

		requestAnimationFrame(() => this.onAnimationFrame());

		if(!this.state.isTimerRunning)
			return;
		
		const now = new Date().getTime();

		this.setState({
			elapsed: now - this.state.start
		});
	}

	onStart()
	{
		this.setState({
			start: new Date().getTime(),
			elapsed: 0,
			isTimerRunning: true
		});
	}

	onStop()
	{
		this.setState({
			isTimerRunning: false
		});
	}

	onReset()
	{
		this.setState({
			elapsed: 0,
			isTimerRunning: false
		});
	}
}