import * as React from "react";
import {
	Box,
	Heading,
	VStack,
	ScrollView,
	Center,
} from "native-base";
import ClickableCard from "../components/ClickableCard";
import Container from "./Container";

const DashboardScreen = ({ navigation }) => {
	return (
		<Container>
			<Heading size="md" fontWeight="medium" color="coolGray.800" ml="-2">Dashboard</Heading>
			<Heading
				ml="-2"
				mt="1"
				mb="4"
				color="coolGray.600"
				fontWeight="600"
				size="lg">Screen</Heading>
			{/* Clickable Card */}
			<ScrollView>
				<Center>
					<ClickableCard
						forwardLink={() => navigation.navigate("InformationStack")}
						color={"#73BD89"}
						title={"Information\nHub"}
						imageSource={require("../assets/images/information.jpeg")}
					/>
					<ClickableCard
						forwardLink={() => navigation.navigate("Prehab")}
						color={"#D11444"}
						title={"Prehab"}
						imageSource={require("../assets/images/prehab.jpg")}
					/>
					<ClickableCard
						forwardLink={() => navigation.navigate("PhysicalActivityStack")}
						color={"#4D4D45"}
						title={"Physical\nActivity"}
						imageSource={require("../assets/images/activity.jpg")}
					/>
					<ClickableCard
						forwardLink={() => navigation.navigate("ProgressStack")}
						color={"#D9D9D9"}
						title={"My\nProgress"}
						imageSource={require("../assets/images/progress.jpg")}
					/>
					<ClickableCard
						forwardLink={() => navigation.navigate("CommunityStack")}
						color={"#1B979A"}
						title={"Community\nHub"}
						imageSource={require("../assets/images/community.jpg")}
					/>
				</Center>
			</ScrollView>
		</Container>
	);
};

export default DashboardScreen;
