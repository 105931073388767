import { Box, VStack, Pressable, HStack } from "native-base";
import React, { Component } from "react";
import { StyleSheet, TouchableOpacity, View, Text, Image } from "react-native";

function ClickableCard({ title, imageSource, color, screenName, forwardLink }) {
	return (
		<TouchableOpacity style={styles.container} onPress={forwardLink}>
			<View style={styles.buttonStack}>
				<Box style={styles.box(color)}>
					<View style={styles.informationHubFiller}></View>
					<Text style={styles.informationHub}>{title}</Text>
				</Box>
				<Image
					source={imageSource}
					resizeMode="cover"
					style={styles.image}
				></Image>
			</View>
		</TouchableOpacity>
	);
}



const styles = {
	container: { 
		marginBottom: 12,
	},
	box: (colorValue) => {
		return {
			top: 0,
			width: 311,
			height: 106,
			position: "absolute",
			backgroundColor: colorValue,
			borderRadius: 10,
			overflow: "hidden",
			left: 0,
		};
	},
	informationHubFiller: {
		flex: 1,
	},
	informationHub: {
		fontFamily: "roboto-regular",
		color: "rgba(255,255,255,1)",
		fontSize: 18,
		marginBottom: 11,
		marginLeft: 23,
	},
	image: {
		top: 0,
		left: 157,
		width: 154,
		height: 106,
		position: "absolute",
		borderTopRightRadius: 10,
		borderBottomRightRadius: 10,
	},
	buttonStack: {
		width: 311,
		height: 107,
	},
};


export default ClickableCard;
