import "react-native-gesture-handler"
import * as React from "react"
//Default Screen Style Options
import defaultScreenOptions from './DefaultOptions';
import DashboardScreen from "../screens/DashboardScreen";
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import { createDrawerNavigator } from "@react-navigation/drawer";
import NavigationDrawerStructure from "./NavDrawerStructure";

const Stack = createStackNavigator();


import LoginScreen from "../screens/LoginScreen";
import IntroductionScreen from "../screens/IntroductionScreen";

function FirstScreenStack({ navigation }) {
	return (
		<Stack.Navigator>

			<Stack.Screen
				name="LoginScreen"
				component={LoginScreen}
				options={{
					title: "Login", //Set Header Title
					headerShown:false,
					headerRight: () => (
						<NavigationDrawerStructure navigationProps={navigation} />
					)}}
			/>
			<Stack.Screen
				name="IntroScreen"
				component={IntroductionScreen}
				options={{
					title: "Introduction", //Set Header Title
					headerShadowVisible: false,
					headerRight: () => (
						<NavigationDrawerStructure navigationProps={navigation} />
					),
					// headerStyle: {
					// 	backgroundColor: "transparent", //Set Header color
					// },
					headerTransparent: true,
					headerTintColor: "#fff", //Set Header text color: ;
					headerTitleStyle: {
						fontWeight: "bold", //Set Header text style
					},
				}}
			/>
		</Stack.Navigator>
	);
}

export default FirstScreenStack