import * as React from "react";
import {
	Box,
	Text,
	Heading,
	VStack,
	Pressable,
	ScrollView,
	Center,
} from "native-base";
import Container from "./Container";

const PostTransplantScreen = ({ navigation }) => {
	return <Container heading="Post Transplant &amp;" subheading="Beyond">
		<ScrollView>
			<Center>
				<Pressable
					onPress={() => navigation.navigate("FatigueManagement")}
				>
					{({ isHovered, isFocused, isPressed }) => {
						return (
							<Box
								width="311"
								mt="4"
								borderWidth="1"
								borderColor="coolGray.300"
								shadow="3"
								bg={
									isPressed
										? "brand.bmtDarkGreen"
										: isHovered
										? "brand.bmtDarkGreen"
										: "brand.bmtGreen"
								}
								p="5"
								rounded="8"
								style={{
									transform: [
										{
											scale: isPressed ? 0.96 : 1,
										},
									],
								}}
							>
								<Text color="#fff" fontWeight="medium" fontSize="xl">
									Fatigue Management
								</Text>
							</Box>
						);
					}}
				</Pressable>
				<Pressable
					onPress={() => navigation.navigate("PhysicalActivity")}
				>
					{({ isHovered, isFocused, isPressed }) => {
						return (
							<Box
								width="311"
								mt="4"
								borderWidth="1"
								borderColor="coolGray.300"
								shadow="3"
								bg={
									isPressed
										? "brand.bmtDarkGreen"
										: isHovered
										? "brand.bmtDarkGreen"
										: "brand.bmtGreen"
								}
								p="5"
								rounded="8"
								style={{
									transform: [
										{
											scale: isPressed ? 0.96 : 1,
										},
									],
								}}
							>
								<Text color="#fff" fontWeight="medium" fontSize="xl">
									Physical Activity
								</Text>
							</Box>
						);
					}}
				</Pressable>
				<Pressable
					onPress={() => navigation.navigate("ReturningToActivities")}
				>
					{({ isHovered, isFocused, isPressed }) => {
						return (
							<Box
								width="311"
								mt="4"
								borderWidth="1"
								borderColor="coolGray.300"
								shadow="3"
								bg={
									isPressed
										? "brand.bmtDarkGreen"
										: isHovered
										? "brand.bmtDarkGreen"
										: "brand.bmtGreen"
								}
								p="5"
								rounded="8"
								style={{
									transform: [
										{
											scale: isPressed ? 0.96 : 1,
										},
									],
								}}
							>
								<Text color="#fff" fontWeight="medium" fontSize="xl">
									Returning to meaningful activities
								</Text>
							</Box>
						);
					}}
				</Pressable>
				<Pressable
					onPress={() =>
						navigation.navigate("PostTransplantDietNutrition")
					}
				>
					{({ isHovered, isFocused, isPressed }) => {
						return (
							<Box
								width="311"
								mt="4"
								borderWidth="1"
								borderColor="coolGray.300"
								shadow="3"
								bg={
									isPressed
										? "brand.bmtDarkGreen"
										: isHovered
										? "brand.bmtDarkGreen"
										: "brand.bmtGreen"
								}
								p="5"
								rounded="8"
								style={{
									transform: [
										{
											scale: isPressed ? 0.96 : 1,
										},
									],
								}}
							>
								<Text color="#fff" fontWeight="medium" fontSize="xl">
									Diet &amp; Nutrition
								</Text>
							</Box>
						);
					}}
				</Pressable>
				<Pressable
					onPress={() => navigation.navigate("FinancialSupport")}
				>
					{({ isHovered, isFocused, isPressed }) => {
						return (
							<Box
								width="311"
								mt="4"
								borderWidth="1"
								borderColor="coolGray.300"
								shadow="3"
								bg={
									isPressed
										? "brand.bmtDarkGreen"
										: isHovered
										? "brand.bmtDarkGreen"
										: "brand.bmtGreen"
								}
								p="5"
								rounded="8"
								style={{
									transform: [
										{
											scale: isPressed ? 0.96 : 1,
										},
									],
								}}
							>
								<Text color="#fff" fontWeight="medium" fontSize="xl">
									Financial Support
								</Text>
							</Box>
						);
					}}
				</Pressable>
			</Center>
		</ScrollView>
	</Container>;
};

export default PostTransplantScreen;
