import * as React from "react";
import {
	Box,
	Text,
	Heading,
	Pressable,
	ScrollView,
	Center,
} from "native-base";
import Container from "./Container";
import EmbeddedDocumentModal from "../components/EmbeddedDocumentModal";

const CommunityHubScreen = ({ navigation }) => {
	return (
		<Container heading="Community" subheading="Hub">
			<ScrollView>
				<Center>
					<EmbeddedDocumentModal 
						title="Upcoming Events"
						url="https://docs.google.com/document/d/e/2PACX-1vQkG93_Kj602rdgYDuSQn5DLfGG-Gc8OZ8HIRduXgoWQ6c5tBOQvKpovqQ6k2jtblWwbPrZEr6Fw3Tc/pub"
					/>
					<EmbeddedDocumentModal 
						title="Patient Forum"
						url="https://docs.google.com/document/d/e/2PACX-1vRQPRS3BtFrekUrvMCDBAB2YTi7vrI0iu63a0d2zGOWNrUj0Yl7ZRCHtXHTGIaykYFZQZdwIYwmQcXf/pub"
					/>
					<EmbeddedDocumentModal 
						title="Links to Macmillan & Anthony Nolan website"
						url="https://docs.google.com/document/d/e/2PACX-1vT_U1sm7lxBGy6BkKdfA5GId8fFUmWuYL1wjTanw5wTO4s5CU7fhyg7ei9L72e1g5aLw0L8CSQPlltY/pub"
					/>
					{/* <Pressable onPress={() => navigation.navigate("AdmissionScreen")}>
						{({ isHovered, isFocused, isPressed }) => {
							return (
								<Box
									width="311"
									mt="4"
									borderWidth="1"
									borderColor="coolGray.300"
									shadow="3"
									bg={
										isPressed
											? "brand.bmtDarkGreen"
											: isHovered
											? "brand.bmtDarkGreen"
											: "brand.bmtGreen"
									}
									p="5"
									rounded="8"
									style={{
										transform: [
											{
												scale: isPressed ? 0.96 : 1,
											},
										],
									}}
								>
									<Text color="#fff" fontWeight="medium" fontSize="xl">
										Upcoming events
									</Text>
								</Box>
							);
						}}
					</Pressable>
					<Pressable onPress={() => navigation.navigate("AdmissionScreen")}>
						{({ isHovered, isFocused, isPressed }) => {
							return (
								<Box
									width="311"
									mt="4"
									borderWidth="1"
									borderColor="coolGray.300"
									shadow="3"
									bg={
										isPressed
											? "brand.bmtDarkGreen"
											: isHovered
											? "brand.bmtDarkGreen"
											: "brand.bmtGreen"
									}
									p="5"
									rounded="8"
									style={{
										transform: [
											{
												scale: isPressed ? 0.96 : 1,
											},
										],
									}}
								>
									<Text color="#fff" fontWeight="medium" fontSize="xl">
										Patient forum
									</Text>
								</Box>
							);
						}}
					</Pressable>
					<Pressable onPress={() => navigation.navigate("AdmissionScreen")}>
						{({ isHovered, isFocused, isPressed }) => {
							return (
								<Box
									width="311"
									mt="4"
									borderWidth="1"
									borderColor="coolGray.300"
									shadow="3"
									bg={
										isPressed
											? "brand.bmtDarkGreen"
											: isHovered
											? "brand.bmtDarkGreen"
											: "brand.bmtGreen"
									}
									p="5"
									rounded="8"
									style={{
										transform: [
											{
												scale: isPressed ? 0.96 : 1,
											},
										],
									}}
								>
									<Text color="#fff" fontWeight="medium" fontSize="xl">
										Links to Macmillan &amp; Anthony Nolan website
									</Text>
								</Box>
							);
						}}
					</Pressable> */}
				</Center>
			</ScrollView>
		</Container>
	);
};

export default CommunityHubScreen;
