import * as React from "react";
import {
	Heading,
	ScrollView,
	Center,
} from "native-base";

import Container from "./Container";
import EmbeddedDocumentModal from "../components/EmbeddedDocumentModal";

const AmbulatoryCareScreen = ({ navigation }) => {
	return (
		<Container heading="Ambulatory" subheading="Care">
			<ScrollView>
				<Center>
					<EmbeddedDocumentModal
						title="What is ambulatory care?"
						url="https://docs.google.com/document/d/e/2PACX-1vT-uMlAsw15Z2uBdAJHk0NzjRxVUS4vnWSdd9lK5jChwG56lHFzFeKqQlflpsvJRg/pub"
					/>
					<EmbeddedDocumentModal
						title="Who can have treatment in ambulatory care?"
						url="https://docs.google.com/document/d/e/2PACX-1vRDYfB87lPEZ--qxZAyXOuwjOfknUxnp2Dz3ckw8nxxqwaxiHAi3MRlAK_SZjJ_KQ/pub"
					/>
					<EmbeddedDocumentModal
						title="What are the advantages?"
						url="https://docs.google.com/document/d/e/2PACX-1vTITNfXOIidmri0VkTUrz23ik526CUXXv0LcrYK3kjz9X47Es4qUPgilR_bHtFrsA/pub"
					/>
					<EmbeddedDocumentModal
						title="A typical day in ambulatory care"
						url="https://docs.google.com/document/d/e/2PACX-1vRHhZZexEFI70EQ9e-r1AkPD6mB0YRcTZJ7r0oTNSUUQTt9AQububwDMUA-ZjQ7BA/pub"
					/>
					<EmbeddedDocumentModal
						title="What happens if I become unwell?"
						url="https://docs.google.com/document/d/e/2PACX-1vQ4qP0rnhk7xNZUyt6swqE1hMaCBbaJLG4p20G72xYA4Uce_2B_Cwzy-iaIszg9Wg/pub"
					/>
				</Center>
			</ScrollView>
		</Container>
	);
};

export default AmbulatoryCareScreen;
