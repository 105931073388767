import * as React from "react";
import {
	Box,
	Text,
	Heading,
	VStack,
	Button,
	ScrollView,
	Center,
	Divider,
	Stack
} from "native-base";
import Container from "./Container";
import RecordLineChart from "../components/RecordLineChart";
import { RECORD_TYPE_FACIT_F } from "../database/RecordType";

import WithRecordsAndChart from "./WithRecordsAndChart";
import QuestionnaireSlider from "../components/QuestionnaireSlider";

export default class FACITQuestionnaireScreen extends WithRecordsAndChart
{
	get recordType()
	{
		return RECORD_TYPE_FACIT_F;
	}

	componentDidMount()
	{
		// NB: Reset state upon mount. This will allow users to not answers questions if they so choose, these appear optional on the paper form
		this.setState({
			fields: {
				HI7:	null,
				HI12:	null,
				An1:	null,
				An2:	null,
				An3:	null,
				An4:	null,
				An5:	null,
				An7:	null,
				An8:	null,
				An12:	null,
				An14:	null,
				An15:	null,
				An16:	null
			}
		});
	}

	get isAnyQuestionAnswered()
	{
		for(let key in this.state.fields)
			if(this.state.fields[key] !== null)
				return true;

		return false;
	}

	calculate(state)
	{
		let sum = 0, answered = 0, count = 0;

		// NB: 1. Answers recorded in this.state.fields. If missing, value for field is null
		// NB: 2. Perform reversals as indicated, and sum individual items to obtain a score
		for(let key in state.fields)
		{
			count++;

			if(state.fields[key] === null)
				continue;

			answered++;

			switch(key)
			{
				case "An5":
				case "An7":
					sum += state.fields[key];
					break;
				
				default:
					sum += 4 - state.fields[key];
					break;
			}
		}

		// NB: 3. Multiply the sum of the item scores by the number of items in the subscale, then divide by the number of items answered. This produces the subscale score.
		const score = Math.round( (sum * count) / answered );

		this.setState({
			value: score
		});
	}

	onRadioChange(key, value)
	{
		const state = {
			...this.state,
			fields: {
				...this.state.fields,
				[key]: parseInt(value)
			}
		};

		this.setState(state);
		this.calculate(state);
	}

	render()
	{
		const friendlyAnswerDescriptions = [
			"Not at all",
			"A little bit",
			"Somewhat",
			"Quite a bit",
			"Very much"
		];

		const radioValues = [4, 3, 2, 1, 0];

		const labels = {
			HI7:	<Text>I feel fatigued</Text>,
			HI12:	<Text>I feel weak all over</Text>,
			An1:	<Text>I feel listless (“washed out”)</Text>,
			An2:	<Text>I feel tired</Text>,
			An3:	<Text>I have trouble <Text underline>starting</Text> things because I am tired</Text>,
			An4:	<Text>I have trouble <Text underline>finishing</Text> things because I am tired</Text>,
			An5:	<Text>I have energy</Text>,
			An7:	<Text>I am able to do my usual activities</Text>,
			An8:	<Text>I need to sleep during the day</Text>,
			An12:	<Text>I am too tired to eat</Text>,
			An14:	<Text>I need help doing my usual activities</Text>,
			An15:	<Text>I am frustrated by being too tired to do the things I want to do</Text>,
			An16:	<Text>I have to limit my social activity because I am tired</Text>
		};

		const items = [];

		// NB: Scoped here so that key is properly retained
		const createItem = key => {

			if(!(key in labels))
				throw new Error(`No label found for ${key}`);

			const friendly = labels[key];

			items.push(
				<Stack key={key}>
					{friendly}
					<QuestionnaireSlider 
						name={key}
						value={this.state.fields[key]}
						values={radioValues}
						friendlyAnswerDescriptions={friendlyAnswerDescriptions}
						onChange={value => this.onRadioChange(key, value)} />
				</Stack>
			);
		};

		for(let key in this.state.fields)
			items.push(createItem(key));

		return (
			<Container>
				<Heading ml="2" size="md" fontWeight="medium" color="coolGray.800">
					FACIT
				</Heading>
				<Heading
					ml="2"
					mt="1"
					mb="4"
					color="coolGray.600"
					fontWeight="600"
					size="lg"
				>
					Fatigue Scale
				</Heading>
				<ScrollView height="80vh">
					<Center>
						<VStack width="90%" mx="3" maxW="300px" alignItems="center">
							<Center>
								<Text italic mb={4} mt={2}>
									Below is a list of statements that other people with your illness have said are important. <Text bold>Please circle or mark one number per line to indicate your response as it applies to the past 7 days.</Text>
								</Text>

								<VStack space={4} divider={<Divider />} maxW="300px">
									{items}
								</VStack>
							</Center>
							{
								this.isAnyQuestionAnswered ?
								<Button
									onPress={() => this.onSubmit()}
									bg="brand.bmtBlue"
									_text={{
										color: "white",
									}}
									mt="4"
									p="4"
									rounded="8"
									shadow={2}
								>
									Submit
								</Button>
								: null
							}
						</VStack>
						<Box width="100%" mt="4">
							<RecordLineChart type={this.recordType}/>
						</Box>
					</Center>
				</ScrollView>
			</Container>
		);
	}
}