import React from "react";
import UserContext from "../contexts/UserContext";
import RecordLineChart from "./RecordLineChart";
import { Box } from "native-base";

export default class ProgressOverview extends React.Component
{
	static contextType = UserContext;

	constructor()
	{
		super();
	}

	render()
	{
		return <>
			{
				this.props.recordTypes.map(type => {
					return (
						<Box marginBottom={3} key={(type)}>
							<RecordLineChart type={type} />
						</Box>
					)
				})
			}
		</>;
	}
}