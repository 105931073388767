import { createStackNavigator } from "@react-navigation/stack";

import defaultScreenOptions from "./DefaultOptions";
import PhysicalActivityMonitoringScreen from "../screens/PhysicalActivityMonitoringScreen";
import SPPBScreen from "../screens/SPPBScreen";
import STSScreen from "../screens/STSSCreen";
import SixMinuteWalkTestScreen from "../screens/SixMinuteWalkTestScreen";
import EQ5D5LQuestionnaireScreen from "../screens/EQ5D5LQuestionnaireScreen";
import FACITQuestionnaireScreen from "../screens/FACITFQuestionnaireScreen";

const Stack = createStackNavigator();

export default function PhysicalActivityStack() {
  return (
    <Stack.Navigator
      initialRouteName="PhysicalActivityScreen"
      screenOptions={{ headerShown: false }}
    >
      <Stack.Screen
        name="PhysicalActivityScreen"
        component={PhysicalActivityMonitoringScreen}
      />
      <Stack.Screen
        name="SPPBScreen"
        component={SPPBScreen}
        options={defaultScreenOptions}
      />
      <Stack.Screen
        name="STS"
        component={STSScreen}
        options={defaultScreenOptions}
      />
      <Stack.Screen
        name="SixMinuteWalkTest"
        component={SixMinuteWalkTestScreen}
        options={defaultScreenOptions}
      />
      <Stack.Screen
        name="FACITFQuestionnaire"
        component={FACITQuestionnaireScreen}
        options={defaultScreenOptions}
      />
      <Stack.Screen
        name="EQ5D5LQuestionnaireScreen"
        component={EQ5D5LQuestionnaireScreen}
        options={defaultScreenOptions}
      />
    </Stack.Navigator>
  );
}
