import { createStackNavigator } from "@react-navigation/stack";

import defaultScreenOptions from "./DefaultOptions";
import PreparingForTransplantScreen from "../screens/PreparingForTransplantScreen";
import DietNutritionScreen from "../screens/DietNutritionScreen";
import PrehabilitationScreen from "../screens/PrehabilitationScreen";
import AmbulatoryCareScreen from "../screens/AmbulatoryCareScreen";
import GeneralAdmissionScreen from "../screens/GeneralAdmissionScreen";

const Stack = createStackNavigator();

export default function PreparingTransplantStack() {
  return (
    <Stack.Navigator
      initialRouteName="PreparingForTransplantScreen"
      screenOptions={{ headerShown: false }}
    >
      <Stack.Screen name="PreparingForTransplantScreen" component={PreparingForTransplantScreen} />
      <Stack.Screen
							name="GeneralAdmissionScreen"
							component={GeneralAdmissionScreen}
							options={defaultScreenOptions}
						/>
      <Stack.Screen
        name="DietNutrition"
        component={DietNutritionScreen}
        options={defaultScreenOptions}
      />
      <Stack.Screen
        name="Prehabilitation"
        component={PrehabilitationScreen}
        options={defaultScreenOptions}
      />
      <Stack.Screen
        name="AmbulatoryCare"
        component={AmbulatoryCareScreen}
        options={defaultScreenOptions}
      />

    </Stack.Navigator>
  );
}
