import React from "react";
import {
	Box,
	Heading,
	VStack,
	Button,
	ScrollView,
	Center,
	Text
} from "native-base";
import { View, StyleSheet } from "react-native";
import Container from "./Container";

const Explainer = ({onProceed}) => {
	 const video = React.useRef(null);
     const [status, setStatus] = React.useState({});

	return (
		<Container>
			<Heading ml="2" size="md" fontWeight="medium" color="coolGray.800">
				SPPB
			</Heading>
			<Heading
				ml="2"
				mt="1"
				mb="4"
				color="coolGray.600"
				fontWeight="600"
				size="lg"
			>
				explainer
			</Heading>
			<ScrollView>
				<Center>
					<VStack width="90%" mx="3" maxW="300px" alignItems="center">
						<Center>
							<Heading size="xs" fontWeight="500" ml={-0.5} mb={2} mt={2}>
								Introduction
							</Heading>
							<View style={styles.container}>
								{/* <Video
								ref={video}
								style={styles.video}
								source={{
									uri: "http://d23dyxeqlo5psv.cloudfront.net/big_buck_bunny.mp4",
								}}
								useNativeControls
								resizeMode="contain"
								isLooping
								onPlaybackStatusUpdate={(status) =>
									setStatus(() => status)
								}
							/> */}
							<Text>Please follow this link and complete the Short Performace Physcial Battery. It has 3 comonents to assess your walking speed, balance and strength. The app will guide you through each step.</Text>
								{/*<video controls width="320px" height="200px">
									<source
										src="https://firebasestorage.googleapis.com/v0/b/bmt-connect.appspot.com/o/videos%2FSPPB%20-%20walking%20script%20NEW_sml.mp4?alt=media&token=c7d50221-e218-463a-b8b7-b9791d5fdbd6"
										type="video/mp4"
									/>
						</video>*/}
								<View style={styles.buttons}>
									<Center>
										
										{/* <Button
										bg="brand.bmtBlue"
										_text={{
											color: "white",
										}}
										mb="4"
										p="5"
										rounded="8"
										width="25%"
										shadow={2}
										onPress={() =>
											status.isPlaying
												? video.current.pauseAsync()
												: video.current.playAsync()
										}
									>
										{status.isPlaying ? "Pause" : "Play"}
									</Button> */}
										<Button
											bg="brand.bmtGreen"
											_text={{
												color: "white",
											}}
											mt="4"
											mb="4"
											p="5"
											rounded="8"
											width="100%"
											shadow={2}
											onPress={() => window.open('https://sppbguide.com/smart-phone-app', '_blank')}
										>
											Continue to SPPB Guide App
										</Button>
									</Center>
								</View>
							</View>
						</Center>
					</VStack>
				</Center>
			</ScrollView>
		</Container>
	);
};

export default Explainer;

const styles = StyleSheet.create({
	video: {
		alignSelf: "center",
		width: 320,
		height: 200,
	},
});