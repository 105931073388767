import React from "react";
import {
	Box,
	Heading,
	VStack,
	Button,
	ScrollView,
	Center,
} from "native-base";

import { Duration } from "luxon";

import Container from "./Container";
import Stopwatch from "./Stopwatch";
import WithTimer from "../../screens/WithTimer";

export default class STS extends WithTimer
{
	onProceed()
	{
		const seconds = this.state.elapsed / 1000;
		let points = 0;

		if(seconds <= 11.19)
			points = 4;
		else if(seconds <= 13.69)
			points = 3;
		else if(seconds <= 16.69)
			points = 2;
		else if(seconds < 60)
			points = 1;
		
		this.props.onProceed({points});
	}

	render()
	{
		return <Container>
			<Heading
				ml="2"
				mt="1"
				mb="4"
				color="coolGray.600"
				fontWeight="600"
				size="lg"
			>
				STS
			</Heading>
			<ScrollView>
				<Center>
					<VStack width="90%" mx="3" maxW="300px" alignItems="center">
						<Center>
							<Heading size="xs" fontWeight="500" ml={-0.5} mb={2} mt={2}>
								Record the time taken to sit to stand five times.
							</Heading>
							<Center>
								<Stopwatch
									elapsed={this.state.elapsed}
									isTimerRunning={this.state.isTimerRunning}
									onStart={() => this.onStart()}
									onStop={() => this.onStop()}
									onReset={() => this.onReset()}
								/>
								{
									this.state.elapsed > 0 && !this.state.isTimerRunning ? 
									<Button
										onPress={() => this.onProceed()}
										bg="brand.bmtBlue"
										_text={{
											color: "white",
										}}
										mt="4"
										p="4"
										rounded="8"
										shadow={2}
									>
										Submit
									</Button>
									: null
								}
							</Center>
						</Center>
					</VStack>
				</Center>
			</ScrollView>
		</Container>;
	}
}