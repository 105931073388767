import { createStackNavigator } from "@react-navigation/stack";
import PostTransplantScreen from "../screens/PostTransplantScreen";
import FatigueManagementScreen from "../screens/FatigueManagementScreen";
import PhysicalActivityScreen from "../screens/PhysicalActivityScreen";
import ReturningToActivitiesScreen from "../screens/ReturningToActivitiesScreen";
import PostTransplantDietNutritionScreen from "../screens/PostTransplantDietNutritionScreen";
import FinancialSupportScreen from "../screens/FinancialSupportScreen";
import defaultScreenOptions from "./DefaultOptions";


const Stack = createStackNavigator();

export default function PostTransplantStack() {
  return (
    <Stack.Navigator
      initialRouteName="PostTransplantScreen"
      screenOptions={{ headerShown: false }}
    >
      <Stack.Screen
        name="PostTransplantScreen"
        component={PostTransplantScreen}
      />
      <Stack.Screen
        name="FatigueManagement"
        component={FatigueManagementScreen}
        options={defaultScreenOptions}
      />
      <Stack.Screen
        name="PhysicalActivity"
        component={PhysicalActivityScreen}
        options={defaultScreenOptions}
      />
      <Stack.Screen
        name="ReturningToActivities"
        component={ReturningToActivitiesScreen}
        options={defaultScreenOptions}
      />
      <Stack.Screen
        name="PostTransplantDietNutrition"
        component={PostTransplantDietNutritionScreen}
        options={defaultScreenOptions}
      />
      <Stack.Screen
        name="FinancialSupport"
        component={FinancialSupportScreen}
        options={defaultScreenOptions}
      />
    </Stack.Navigator>
  );
}
