import * as React from "react";
import {
	ScrollView,
	Center
} from "native-base";
import ClickableCard from "../components/ClickableCard";
import Container from "./Container";

const InformationHubScreen = ({navigation}) => {
	return <Container heading="Information" subheading="Hub">
		<ScrollView>
			<Center>
				<ClickableCard
					forwardLink={() => navigation.navigate("PreparingStack")}
					color={"#73BD89"}
					title={"Preparing for\nTransplant"}
					imageSource={require("../assets/images/packing.png")}
				/>
				<ClickableCard
					forwardLink={() => navigation.navigate("HospitalStayStack")}
					color={"#D11444"}
					title={"My Hospital\nStay"}
					imageSource={require("../assets/images/heathhospital.jpeg")}
				/>
				<ClickableCard
					forwardLink={() => navigation.navigate("TransplantStack")}
					color={"#4D4D45"}
					title={"Post transplant\n& beyond"}
					imageSource={require("../assets/images/activity.jpg")}
				/>
				<ClickableCard
					forwardLink={() => navigation.navigate("OtherInfo")}
					color={"#1B979A"}
					title={"Other info\n& Podcasts"}
					imageSource={require("../assets/images/community.jpg")}
				/>
			</Center>
		</ScrollView>
	</Container>;
};

export default InformationHubScreen;