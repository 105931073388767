import React, { useState } from "react";
import {
	Box,
	Heading,
	VStack,
	Select,
	Button,
	CheckIcon,
	ScrollView,
	Center
} from "native-base";
import Container from "./Container";

import RecordLineChart from "../components/RecordLineChart";
import WithRecordsAndChart from "./WithRecordsAndChart";
import { RECORD_TYPE_FATIGUE } from "../database/RecordType";

export default class FatigueScoreScreen extends WithRecordsAndChart
{
	get recordType()
	{
		return RECORD_TYPE_FATIGUE;
	}

	render()
	{
		return <Container>
			<Heading ml="2" size="md" fontWeight="medium" color="coolGray.800">
				Fatigue Outcome
			</Heading>
			<Heading
				ml="2"
				mt="1"
				mb="4"
				color="coolGray.600"
				fontWeight="600"
				size="lg"
			>
				Measure
			</Heading>
			<ScrollView>
				<Center>
					<VStack width="90%" mx="3" maxW="300px" alignItems="center">
						<Center>
							<Heading
								size="xs"
								fontWeight="500"
								ml={-0.5}
								mb={2}
								mt={2}
							>
								Please select your fatigue level below.
							</Heading>
							<Box maxW="300">
								<Select
									selectedValue={this.state.value}
									minWidth="200"
									accessibilityLabel="Choose Score"
									placeholder="Choose Score"
									_selectedItem={{
										bg: "teal.600",
										endIcon: <CheckIcon size="5" />,
									}}
									mt={1}
									onValueChange={value => this.onValueChanged(value)}
								>
									<Select.Item label="0" value="0" />
									<Select.Item label="1" value="1" />
									<Select.Item label="2" value="2" />
									<Select.Item label="3" value="3" />
									<Select.Item label="4" value="4" />
									<Select.Item label="5" value="5" />
									<Select.Item label="6" value="6" />
									<Select.Item label="7" value="7" />
									<Select.Item label="8" value="8" />
									<Select.Item label="9" value="9" />
									<Select.Item label="10" value="10" />
								</Select>
								<Button
									onPress={() => this.onSubmit()}
									bg="brand.bmtBlue"
									_text={{
										color: "white",
									}}
									mt="4"
									p="4"
									rounded="8"
									shadow={2}
								>
									Submit
								</Button>
							</Box>
						</Center>
					</VStack>
					<Box width="100%" mt="4">
						<RecordLineChart type={this.recordType}/>
					</Box>
				</Center>
			</ScrollView>
		</Container>;
	}
};