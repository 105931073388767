import React from "react";
import {
	Button,
	Modal,
	View
} from "native-base";
import { useState } from "react";
import Ionicons from "react-native-vector-icons/Ionicons";
import EmbeddedDocument from "./EmbeddedDocument";

export default function EmbeddedDocumentModal({title, url}) {
	const [showModal, setShowModal] = useState(false);
	return (
		<>
			<Button
				bg="brand.bmtBlue"
				_text={{
					color: "white",
				}}
				mb="4"
				width="311"
				shadow={2}
				leftIcon={<Ionicons name="document-outline" color="white"/>}
				onPress={() => setShowModal(true)}
			>
				{title}
			</Button>
			<Modal isOpen={showModal} onClose={() => setShowModal(false)}>
				<Modal.Content minWidth="90%">
					<Modal.CloseButton />
					<View minHeight="90%">
						<EmbeddedDocument url={url}/>
					</View>
				</Modal.Content>
			</Modal>
		</>
	);
}
