import { createStackNavigator } from "@react-navigation/stack";

import PrehabInBmtScreen from "../screens/PrehabInBmtScreen";
import PodcastsFaqScreen from "../screens/PodcastsFaqScreen";
import InformationHubScreen from "../screens/InformationHubScreen";
import defaultScreenOptions from "./DefaultOptions";
import HospitalStayStack from "./HospitalStayStack";
import PhysicalActivityStack from "./PhysicalActivityStack";
import PreparingTransplantStack from "./PreparingTrasplantStack";
import PostTransplantStack from "./PostTransplantStack";

const Stack = createStackNavigator();

export default function InformationStack() {
  return (
    <Stack.Navigator
      initialRouteName="Information"
      screenOptions={{ headerShown: false, title: "Information" }}
    >
      <Stack.Screen name="Information" component={InformationHubScreen} />
      <Stack.Screen
        name="PreparingScreen"
        component={PrehabInBmtScreen}
        options={defaultScreenOptions}
      />
      <Stack.Screen
        name="TransplantStack"
        component={PostTransplantStack}
        options={defaultScreenOptions}
      />
      <Stack.Screen
        name="HospitalStayStack"
        component={HospitalStayStack}
        options={defaultScreenOptions}
      />
      <Stack.Screen
        name="OtherInfo"
        component={PodcastsFaqScreen}
        options={defaultScreenOptions}
      />
      <Stack.Screen
        name="PreparingStack"
        component={PreparingTransplantStack}
        options={defaultScreenOptions}
      />
    </Stack.Navigator>
  );
}
