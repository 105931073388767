import * as React from "react";
import {
	ScrollView,
	Center,
} from "native-base";
import Container from "./Container";
import EmbeddedDocumentModal from "../components/EmbeddedDocumentModal";

const PotentialSideEffectsScreen = ({ navigation }) => {
	return <Container heading="Potential" subheading="side effects">
		<ScrollView>
			<Center>
				<EmbeddedDocumentModal
					title="Altered Bowel Habit"
					url="https://docs.google.com/document/d/e/2PACX-1vQEe92FJql_vdYA_3DdKcH8x4QUAAAgIMKFwWvfHxEw54ThGSFx0iumx8zkFPP6NQ/pub"
				/>
				<EmbeddedDocumentModal
					title="Dry Mouth"
					url="https://docs.google.com/document/d/e/2PACX-1vSBUjHU46NgRYgrtYoN75VcMUFVZ8MUIsmetYUOaFsyFedtI5pMnvrZYQme3nBk8w/pub"
				/>
				<EmbeddedDocumentModal
					title="Feeling Sick"
					url="https://docs.google.com/document/d/e/2PACX-1vQvjA0rittnuEuOPle6n3O8OeZ-2Mp-MOe5BHnlbClVOqkEZH1aVqklMHexoNDbZg/pub"
				/>
				<EmbeddedDocumentModal
					title="Graft vs Host Disease"
					url="https://docs.google.com/document/d/e/2PACX-1vQQ2mlC1Y_jXJSY7W-JZMXi2yYgZ4hlSMw0gRshM1wBEsPxusUccqy82bLR5fMhkw/pub"
				/>
				<EmbeddedDocumentModal
					title="Mucositis"
					url="https://docs.google.com/document/d/e/2PACX-1vSlCQz57OxLz5z2rYqSWKxBT-QQWe6eWCN5_DMgZAeTwyOBOFc7Y1Frf7VUoUmAwQ/pub"
				/>
				<EmbeddedDocumentModal
					title="Taste Changes"
					url="https://docs.google.com/document/d/e/2PACX-1vShaIz3DB5c2ebLP8Mm54OVjVgdR4nnE_ooePMR8GILHWkGz7zw3iVeBd5UL50jVg/pub"
				/>
			</Center>
		</ScrollView>
	</Container>;
};

export default PotentialSideEffectsScreen;
