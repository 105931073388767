import { createStackNavigator } from "@react-navigation/stack";

import CommunityHubScreen from "../screens/CommunityHubScreen";

const Stack = createStackNavigator();

export default function CommunityStack() {
  return (
    <Stack.Navigator
      initialRouteName="Community"
      screenOptions={{ headerShown: false }}
    >
      <Stack.Screen name="Community" component={CommunityHubScreen} />
      {/* add community routes here :) */}
    </Stack.Navigator>
  );
}
