import React from "react";
import {
	Box,
	VStack,
	Center
} from "native-base";

export default class Container extends React.Component
{
	render()
	{
		return <Center flex={1}>
			<VStack flex={1} justifyContent="center" maxW="96">
				<Box p="2" mx="auto" minWidth="100%">
					{this.props.children}
				</Box>
			</VStack>
		</Center>;
	}
}