import React from "react";
import {
	Box,
	Heading,
	VStack,
	Button,
	ScrollView,
	Center
} from "native-base";
import Container from "./Container";

const Score = ({ points, navigation }) => {
	return <Container>
		<Heading ml="2" size="md" fontWeight="medium" color="coolGray.800">
			SPPB
		</Heading>
		<Heading
			ml="2"
			mt="1"
			mb="4"
			color="coolGray.600"
			fontWeight="600"
			size="lg"
		>
			Balance Score
		</Heading>
		<ScrollView>
			<Center>
				<VStack width="90%" mx="3" maxW="300px" alignItems="center">
					<Center>
						<Heading size="xs" fontWeight="500" ml={-0.5} mb={2} mt={2}>
							score
						</Heading>
						<Box
							width="100%"
							bg="white"
							p="4"
							shadow={2}
							_text={{
								fontSize: "6xl",
								fontWeight: "bold",
								color: "black",
							}}
							borderWidth={2}
							borderRadius={4}
						>
							{points}
						</Box>
					</Center>
					<Button
						bg="brand.bmtGreen"
						_text={{
							color: "white",
						}}
						mt="4"
						p="5"
						rounded="8"
						width="100%"
						shadow={2}
						onPress={() =>
							navigation.goBack()
						}
					>
						Back to monitoring tools
					</Button>
				</VStack>
				<Box width="100%" mt="4">
					{/* <LineChart data={chartData} /> */}
				</Box>
			</Center>
		</ScrollView>
	</Container>;
};

export default Score;
