import * as React from "react";
import {
	Box,
	Heading,
	VStack,
	ScrollView,
	Center,
} from "native-base";
import Container from "./Container";
import EmbeddedDocumentModal from "../components/EmbeddedDocumentModal";

const FinancialSupportScreen = ({ navigation }) => {
	return <Container title="Financial" subheading="Support">
		<ScrollView>
			<Center>
				<EmbeddedDocumentModal
					title="Access to work"
					url="https://docs.google.com/document/d/e/2PACX-1vRJGODu_FJ8vV91rPw3AHv7n3eVUdvIj2SdrWwNYzzi-8mkQEpAwj7q8hcabxagZA/pub"
				/>
				<EmbeddedDocumentModal
					title="Financial support"
					url="https://docs.google.com/document/d/e/2PACX-1vSaMdjyfJribAchQYWgDywf6ox6EToD2SLCnHk1UkYoQlvZgddNbLdEU1qGh9wASA/pub"
				/>
			</Center>
		</ScrollView>
	</Container>;
};

export default FinancialSupportScreen;
