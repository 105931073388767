import React from "react";
import {
	StyleSheet,
	View,
	ImageBackground,
	Text,
	TouchableOpacity,
} from "react-native";
import Icon from "react-native-vector-icons/Entypo";

function IntroductionScreen(props) {
	return (
		<View style={styles.container}>
			<ImageBackground
				source={require("../assets/images/akin-cakiner-EwIzEEPS0Qg-unsplash.jpg")}
				resizeMode="cover"
				style={styles.image}
				imageStyle={styles.image_imageStyle}
			>
				<View style={styles.containerElements}>
					<Text style={styles.Introduction}>{"Introduction"}</Text>

					<Text style={styles.TextSubHeader}>
						{
							"Please take some time to watch this short introduction video. It will guide you through the app’ and how to gather more information."
						}
					</Text>

					<View style={styles.ButtonContainer}>
						<TouchableOpacity
							onPress={() => props.navigation.navigate("Dashboard")}
							style={styles.button}
						>
							<Icon name="chevron-thin-right" style={styles.icon}></Icon>
						</TouchableOpacity>
					</View>
				</View>
			</ImageBackground>
		</View>
	);
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
	},
	image: {
		height: "100%",
		justifyContent: "space-around",
		paddingLeft: 16,
		paddingRight: 16,
	},
	Introduction: {
		fontFamily: "roboto-black",
		color: "rgba(255,255,255,1)",
		fontSize: 36,
		fontWeight: "700",
		marginBottom: 8,
	},
	button: {
		width: 46,
		height: 46,
	},
	icon: {
		color: "rgba(255,255,255,1)",
		fontSize: 40,
		height: 40,
		width: 40,
		marginLeft: 3,
	},
	TextSubHeader: {
		color: "#fff",
		textAlign: "left",
		fontWeight: "500",
		fontSize: 24,
	},
	ButtonContainer: {
		alignItems: "flex-start",
		justifyContent: "center",
		alignSelf: "flex-end",
		marginTop: 4,
	},
	containerElements: {
		justifyContent: "center",
		width: "80%",
		alignSelf: "center",
	},
});

export default IntroductionScreen;
