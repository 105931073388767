import * as React from "react";
import {
	Box,
	Heading,
	VStack,
	ScrollView,
	Center,
} from "native-base";
import EmbeddedDocumentModal from "../components/EmbeddedDocumentModal";
import Container from "./Container";

const StayingActiveInHospitalScreen = ({ navigation }) => {
	return <Container heading="Tips on staying" subheading="active in hospital">
		<ScrollView>
			<Center>
				<EmbeddedDocumentModal 
					title="Benefits of Daily Routine" 
					url="https://docs.google.com/document/d/e/2PACX-1vQyIr2AGFdzdJ7SsXP1-T9gmdMne6oHzX2_53YFUk2heAEuwXDScSks0qteBQtiKg/pub"
				/>
				<EmbeddedDocumentModal 
					title="Tips on staying active in Hospital" 
					url="https://docs.google.com/document/d/e/2PACX-1vSJO3_sY8niWwKpTY_7itpEwTS0b1NJlNXRYOGgHI1lxddeNv_brk_qDlCItW7Hzw/pub"
				/>
				<EmbeddedDocumentModal 
					title="Sleep Hygiene" 
					url="https://docs.google.com/document/d/e/2PACX-1vS1wqQOxGzn41hj8L92Og83LHd_NxsdX_Vj4pbITSW-dXhryzo7k_0W4uTvKD3xOA/pub"
				/>
			</Center>
		</ScrollView>
	</Container>
};

export default StayingActiveInHospitalScreen;
