import * as React from "react";
import { SafeAreaView } from "react-native";
import {
	Box,
	Text,
	Heading,
	VStack,
	Button,
	ScrollView,
	Center,
	Divider,
	Stack,
	Slider,
	Flex
} from "native-base";
import { Entypo } from '@expo/vector-icons';
import RecordLineChart from "../components/RecordLineChart";
import { RECORD_TYPE_EQ5D5L } from "../database/RecordType";
import { RECORD_TYPE_HEALTH_STATE_VISUAL_SCALE } from "../database/RecordType";

import WithRecordsAndChart from "./WithRecordsAndChart";
import QuestionnaireSlider from "../components/QuestionnaireSlider";

export default class EQ5D5LQuestionnaireScreen extends WithRecordsAndChart
{
	constructor()
	{
		super();

		this.state = {
			fields: {
				mobility:			null,
				selfCare:			null,
				usualActivities:	null,
				painDiscomfort:		null,
				anxietyDepression:	null
			},
			healthStateVisualScaleValue: 50
		};

		this.fieldDefinitions = [
			{
				stateKey:	"mobility",
				label:		<Text>Mobility</Text>,
				suffix:		"problems in walking about",
				overrides:	{
					5:		"I am unable to walk about"
				}
			},
			{
				stateKey:	"selfCare",
				label:		<Text>Self-care</Text>,
				suffix:		"problems washing or dressing myself",
				overrides:	{
					5:		"I am unable to wash or dress myself"
				}
			},
			{
				stateKey:	"usualActivities",
				label:		<><Text>Usual Activities</Text> <Text italic>(e.g. work, study, housework, family or leisure activities</Text></>,
				suffix:		"problems doing my usual activities",
				overrides:	{
					5:		"I am unable to do my usual activities"
				}
			},
			{
				stateKey:	"painDiscomfort",
				label:		<Text>Pain / Discomfort</Text>,
				suffix:		"pain or discomfort",
				overrides:	{
					5:		"I have extreme pain or discomfort"
				}
			},
			{
				stateKey:	"anxietyDepression",
				label:		<Text>Anxiety / Depression</Text>,
				suffix:		"anxious or depressed",
				overrides:	{
					1:		"I am not anxious or depressed",
					2:		"I am slightly anxious or depressed",
					3:		"I am moderately anxious or depressed",
					4:		"I am severely anxious or depressed",
					5:		"I am extremely anxious or depressed"
				}
			}
		];
	}

	calculate(state)
	{
		let score = 0;

		for(var key in state.fields)
			score += state.fields[key];

		this.setState({eq5d5lValue: score});
	}

	onRadioChange(key, value)
	{
		const state = {
			...this.state,
			fields: {
				...this.state.fields,
				[key]: parseInt(value)
			}
		};

		this.setState(state);
		this.calculate(state);
	}

	onSliderChange(value)
	{
		this.setState({
			healthStateVisualScaleValue: value
		});
	}

	onSubmitEQ5D5L()
	{
		this.submitRecord(RECORD_TYPE_EQ5D5L, this.state.eq5d5lValue);
	}

	onSubmitHealthStateVisualScale()
	{
		this.submitRecord(RECORD_TYPE_HEALTH_STATE_VISUAL_SCALE, this.state.healthStateVisualScaleValue);
	}

	render()
	{
		const radioValues = [1, 2, 3, 4, 5];

		const items = this.fieldDefinitions.map((definition, index) => {

			const friendlyAnswerDescriptions = {
				1: `I have no ${definition.suffix}`,
				2: `I have slight ${definition.suffix}`,
				3: `I have moderate ${definition.suffix}`,
				4: `I have severe ${definition.suffix}`,
				5: `I am unable ${definition.suffix}`
			};

			if("overrides" in definition)
				Object.assign(friendlyAnswerDescriptions, definition.overrides);

			return <Stack key={index}>
				{definition.label}
				<QuestionnaireSlider 
					value={this.state.fields[definition.stateKey]}
					values={radioValues}
					friendlyAnswerDescriptions={friendlyAnswerDescriptions}
					onChange={value => this.onRadioChange(definition.stateKey, value)}
					/>
			</Stack>;

		});

		return (
			<Center flex={1}>
				<VStack flex={1} justifyContent="center" maxW="96">
					<Box p="2" mx="auto" minWidth="100%">
						<Heading ml="2" size="md" fontWeight="medium" color="coolGray.800">
							EQ-5D-5L
						</Heading>
						<ScrollView height="80vh">
							<Center>
								<VStack width="90%" mx="3" maxW="300px" alignItems="center">
									<Stack>
										<Center>
											<Text italic mb={4} mt={2}>
												Below is a list of statements that other people with your illness have said are important. <Text bold>Please circle or mark one number per line to indicate your response as it applies to the past 7 days.</Text>
											</Text>

											<VStack space={4} divider={<Divider />} maxW="300px">
												{items}
											</VStack>
										</Center>
										<Button
											onPress={() => this.onSubmitEQ5D5L()}
											bg="brand.bmtBlue"
											_text={{
												color: "white",
											}}
											mt="4"
											p="4"
											rounded="8"
											shadow={2}
										>
											Submit
										</Button>
										<Box width="100%" mt="4">
											<RecordLineChart type={RECORD_TYPE_EQ5D5L}/>
										</Box>
									</Stack>
									<Stack>
										<Center>
											<Text mb={4} mt={2}>
												We want to know how good or bad your health is <Text bold>today.</Text>
											</Text>
											<Text>
												From left to right, 0 is the worst imaginable health state, 100 is the best imaginable health state.
											</Text>
										</Center>
										<Box>
											<Slider 
												minValue={0}
												maxValue={100}
												defaultValue={50} 
												colorScheme="cyan"
												onChange={value => this.onSliderChange(value)}
												onChangeEnd={value => this.onSliderChange(value)}>
												<Slider.Track>
													<Slider.FilledTrack />
												</Slider.Track>
												<Slider.Thumb />
											</Slider>
											<Flex flexDirection="row" justifyContent="space-between">
												<Entypo name="emoji-sad" size={24} color="black" />
												<Entypo name="emoji-happy" size={24} color="black" />
											</Flex>
										</Box>
										<Button
											onPress={() => this.onSubmitHealthStateVisualScale()}
											bg="brand.bmtBlue"
											_text={{
												color: "white",
											}}
											mt="4"
											p="4"
											rounded="8"
											shadow={2}
										>
											Submit
										</Button>
										<Box width="100%" mt="4">
											<RecordLineChart type={RECORD_TYPE_HEALTH_STATE_VISUAL_SCALE}/>
										</Box>
									</Stack>
								</VStack>
							</Center>
						</ScrollView>
					</Box>
				</VStack>
			</Center>
		);
	}
}