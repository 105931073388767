import {
	RECORD_TYPE_WEIGHT,
	RECORD_TYPE_WHITE_BLOOD_CELLS,
	RECORD_TYPE_HAEMOGLOBIN,
	RECORD_TYPE_PLATELETS,
	RECORD_TYPE_NEUTROPHILS,
	RECORD_TYPE_FATIGUE,
	RECORD_TYPE_ACTIVITY_LEVEL,
	RECORD_TYPE_SPPB,
	RECORD_TYPE_GODIN,
	RECORD_TYPE_1_MIN_STS,
	RECORD_TYPE_FACIT_F,
	RECORD_TYPE_EQ5D5L,
	RECORD_TYPE_HEALTH_STATE_VISUAL_SCALE,
	RECORD_TYPE_SIX_MINUTE_WALK_TEST_TIME_ELAPSED,
	RECORD_TYPE_SIX_MINUTE_WALK_TEST_DISTANCE_TRAVELLED
} from "./RecordType";

const RecordTypeUnits = {
	[RECORD_TYPE_WEIGHT]: "kg",
	[RECORD_TYPE_WHITE_BLOOD_CELLS]: "g/L",
	[RECORD_TYPE_HAEMOGLOBIN]: "g/L",
	[RECORD_TYPE_PLATELETS]: "g/L",
	[RECORD_TYPE_NEUTROPHILS]: "g/L",
	[RECORD_TYPE_FATIGUE]: "points",
	[RECORD_TYPE_ACTIVITY_LEVEL]: "points",
	[RECORD_TYPE_SPPB]: "points",
	[RECORD_TYPE_GODIN]: "points",
	[RECORD_TYPE_1_MIN_STS]: "repetitions",
	[RECORD_TYPE_FACIT_F]: "points",
	[RECORD_TYPE_EQ5D5L]: "points",
	[RECORD_TYPE_HEALTH_STATE_VISUAL_SCALE]: "points",
	[RECORD_TYPE_SIX_MINUTE_WALK_TEST_TIME_ELAPSED]: "seconds",
	[RECORD_TYPE_SIX_MINUTE_WALK_TEST_DISTANCE_TRAVELLED]: "meters"
};

export default RecordTypeUnits;