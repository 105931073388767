import { getHeaderTitle } from '@react-navigation/elements';

const defaultScreenOptions = {
	title: "", //Set Header Title
	headerShadowVisible: false,
	cardStyle: {
		backgroundColor: "#fff",
	},
	headermode:screen,
	header: ({ navigation, route, options, back }) => {
		const title = getHeaderTitle(options, route.name);
	  
		return (
		  <MyHeader
			title={title}
			leftButton={
			  back ? <MyBackButton onPress={navigation.goBack} /> : undefined
			}
			style={options.headerStyle}
		  />
		);
	  }
}

// export default defaultScreenOptions