import * as React from "react";
import {
	Box,
	Heading,
	VStack,
	ScrollView,
	Center,
} from "native-base";
import Container from "./Container";
import EmbeddedDocumentModal from "../components/EmbeddedDocumentModal";

const DietNutritionHospitalStayScreen = ({ navigation }) => {
	return (
		<Container heading="Diet &amp;" subheading="Nutrition">
			<ScrollView>
				<Center>
					<EmbeddedDocumentModal
						title="Bringing food into the Hospital"
						url="https://docs.google.com/document/d/e/2PACX-1vTG99WAQeoXPlt5-CxbMSOlqW-3b09pQgWi4F5wBgp2jbg82jIUOAEcm25JgVQFHw/pub"
					/>
					<EmbeddedDocumentModal
						title="Hospital food"
						url="https://docs.google.com/document/d/e/2PACX-1vSoEr3hGBJWs1zNTyY0rxgGCGJTVA-wmfp9zn6WpHVsiWtVA4yRHb9lSOiYVdJ-ew/pub"
					/>
					<EmbeddedDocumentModal
						title="Loss of appetite"
						url="https://docs.google.com/document/d/e/2PACX-1vRpOSsnKIcdVdt8Z4lVdmtzPuUNJDxysLcZ7qMyUbcyv-40wu4GdRJ7BJEPD5ZayA/pub"
					/>
					<EmbeddedDocumentModal
						title="Nasogastric feeding"
						url="https://docs.google.com/document/d/e/2PACX-1vRoyvr5m4QQ7fPJO7mIP4svcNKMoWamVmrguoJ-aUYImwhklzPCUz9HIWL6lZKMiQ/pub"
					/>
					<EmbeddedDocumentModal
						title="Nutritional Supplements"
						url="https://docs.google.com/document/d/e/2PACX-1vSvlznhMZZ2207chNNtG4eUcvTKJ9ANNxhWrHZDUxjpN-mmV9OlAhQmKYFqo2Xhiw/pub"
					/>
				</Center>
			</ScrollView>
		</Container>
	);
};

export default DietNutritionHospitalStayScreen;
