import * as React from "react";
import {
	Box,
	Heading,
	VStack,
	ScrollView,
	Center,
} from "native-base";
import Container from "./Container";
import EmbeddedDocumentModal from "../components/EmbeddedDocumentModal";

const LookingAfterYourChestScreen = ({ navigation }) => {
	return <Container heading="Looking after" subheading="your chest">
		<ScrollView>
			<Center>
				<EmbeddedDocumentModal
					title="Looking after your chest"
					url="https://docs.google.com/document/d/e/2PACX-1vTCFAcNXGv9gGkPKp5LUokEeV2rPUHaRYTi6lNlERoa6wVlexUHP7fj3nUFoAe-qg/pub"
				/>
			</Center>
		</ScrollView>
	</Container>;
};

export default LookingAfterYourChestScreen;
