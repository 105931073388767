import * as React from "react";
import {
	Heading,
	ScrollView,
	Center,
} from "native-base";
import EmbeddedDocumentModal from "../components/EmbeddedDocumentModal";
import Container from "./Container";

const Day0Screen = ({ navigation }) => {
	return (
		<Container heading="Day 0" subheading="reading">
			<ScrollView>
				<Center>
					<EmbeddedDocumentModal
						title="Allogeneic Cells"
						url="https://docs.google.com/document/d/e/2PACX-1vQOHfs0SpNlOJ89byDp05cTgP4VMRIVGtsDPEekW7g7-5EGpZ2xAg8MKUdByHakXA/pub"
					/>
					<EmbeddedDocumentModal
						title="Autologous cells"
						url="https://docs.google.com/document/d/e/2PACX-1vTnZsla2x1TzxlphPLt17Xy0a7zpL2-dALv9vI8peUCQyBJAq4vYXjAgt-qEcQfMA/pub"
					/>
					<EmbeddedDocumentModal
						title="Transplant of Cells"
						url="https://docs.google.com/document/d/e/2PACX-1vT5rgOOvud3kkiOZPL5Yb8294memEcdbJwMta3accCIMFcmY3nAZCwt-C5q_vo8sg/pub"
					/>
				</Center>
			</ScrollView>
		</Container>
	);
};

export default Day0Screen;
