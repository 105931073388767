import * as React from "react";
import {
	Box,
	Text,
	Heading,
	VStack,
	Pressable,
	ScrollView,
	Center,
} from "native-base";
import Container from "./Container";
import EmbeddedDocumentModal from "../components/EmbeddedDocumentModal";

const PostTransplantMonitoringScreen = ({ navigation }) => {
	return <Container heading="My post-transplant monitoring" subheading="(2 weekly)">
		<ScrollView>
			<Center>
				<EmbeddedDocumentModal
					title="My post transplant monitoring information"
					url="https://docs.google.com/document/d/e/2PACX-1vRMrLUHpF0ieE0_3IMZGgltH17k4rPonl0N9rke4ixkxAqxyNI4gyX2X1omPErq5A/pub"
				/>
				<Pressable onPress={() => navigation.navigate("MyWeight")}>
					{({ isHovered, isFocused, isPressed }) => {
						return (
							<Box
								width="311"
								mt="4"
								borderWidth="1"
								borderColor="coolGray.300"
								shadow="3"
								bg="brand.bmtGreen"
								p="5"
								rounded="8"
								style={{
									transform: [
										{
											scale: isPressed ? 0.96 : 1,
										},
									],
								}}
							>
								<Text color="#fff" fontWeight="medium" fontSize="xl">
									My weight
								</Text>
							</Box>
						);
					}}
				</Pressable>
				<Pressable onPress={() => navigation.navigate("FatigueScore")}>
					{({ isHovered, isFocused, isPressed }) => {
						return (
							<Box
								width="311"
								mt="4"
								borderWidth="1"
								borderColor="coolGray.300"
								shadow="3"
								bg="brand.bmtYellow"
								p="5"
								rounded="8"
								style={{
									transform: [
										{
											scale: isPressed ? 0.96 : 1,
										},
									],
								}}
							>
								<Text color="#fff" fontWeight="medium" fontSize="xl">
									Fatigue Score
								</Text>
							</Box>
						);
					}}
				</Pressable>
				<Pressable
					onPress={() => navigation.navigate("GODINQuestionnnaire")}
				>
					{({ isHovered, isFocused, isPressed }) => {
						return (
							<Box
								width="311"
								mt="4"
								borderWidth="1"
								borderColor="coolGray.300"
								shadow="3"
								bg="brand.bmtDarkGreen"
								p="5"
								rounded="8"
								style={{
									transform: [
										{
											scale: isPressed ? 0.96 : 1,
										},
									],
								}}
							>
								<Text color="#fff" fontWeight="medium" fontSize="xl">
									GODIN questionnaire
								</Text>
							</Box>
						);
					}}
				</Pressable>
			</Center>
		</ScrollView>
	</Container>;
};

export default PostTransplantMonitoringScreen;
