import React from "react";

import { Box } from "native-base";
import { Pressable } from "react-native";
import { StyleSheet } from "react-native-web";
import Ionicons from "react-native-vector-icons/Ionicons";

export default function HeaderButtons({ navigation, user }) {
  const styles = StyleSheet.create({
    button: {
      borderRadius: 8,
      marginLeft: 24,
      paddingHorizontal: 24,
      paddingVertical: 8,
    },
  });
  
  const handleBack = (navigation) => {
    navigation.goBack();
  };

  const handleHome = (navigation) => {
    if (navigation) navigation.navigate("Dashboard");
  };

  return (
    <Box flexDirection="row" marginLeft={8}>
      <Pressable
        onPress={() => handleHome(navigation)}
        disabled={!user.isLoggedIn}
      >
        <img
          src={require("../assets/images/bmt-connect-logo-reverse-rgb-540px@72ppi.png")}
          style={{
            width: 100,
            height: 41,
            filter: "invert(100%)",
          }}
        />
      </Pressable>
      {navigation.canGoBack() && user.isLoggedIn && (
        <Pressable
          onPress={() => handleBack(navigation)}
        >
          <Box
            style={styles.button}
          >
            <Ionicons
              size={26}
              color={"#000000"}
              name="arrow-back-outline"
            />
          </Box>
        </Pressable>
      )}
    </Box>
  );
}
