import * as React from "react";
import {
	Box,
	Heading,
	VStack,
	ScrollView,
	Center,
} from "native-base";
import Container from "./Container";
import EmbeddedDocumentModal from "../components/EmbeddedDocumentModal";

const PhysicalActivityScreen = ({ navigation }) => {
	return <Container heading="Physical" subheading="Activity">
		<ScrollView>
			<Center>
				<EmbeddedDocumentModal
					title="Physical Activity"
					url="https://docs.google.com/document/d/e/2PACX-1vTTmP6Em3g6e00nI4nnnMvUKu2Mlvt5CPfGDmT4uJnrnT2rTwXSMuuIN8lmvDMucg/pub"
				/>
				<EmbeddedDocumentModal
					title="Self Referral - Physiotherapy"
					url="https://docs.google.com/document/d/e/2PACX-1vT4XlDOvY95RLJpL8So-6mUa-eOTcHfdNCN6FaRLJKeX5f_OZPyKEjHXfZby3ttag/pub"
				/>
			</Center>
		</ScrollView>
	</Container>;
};

export default PhysicalActivityScreen;
