import React from "react";
import UserContext from "../contexts/UserContext";
import db from "../database/database";

export default class WithRecordsAndChart extends React.Component
{
	static contextType = UserContext;

	constructor()
	{
		super();

		this.state = {
			value: null
		};
	}

	get recordType()
	{
		throw new Error("Abstract function called");
	}

	submitRecord(type, value)
	{
		const user		= this.context.user;
		const parsed	= parseInt( value );

		if(isNaN(parsed))
			throw new Error("Value must not be NaN");

		return db
			.records
			.add({
				type:		type,
				points:		parsed,	// NB: Important to cast this or the chart doesn't read it correctly
				timestamp:	new Date(),
				idusers:	user.id
			})
			.then(() => this.forceUpdate());
	}

	onValueChanged(value)
	{
		this.setState({
			value: value
		});
	}

	onSubmit()
	{
		this.submitRecord(this.recordType, this.state.value);
	}

	
}