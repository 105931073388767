import * as React from "react";
import {
	Box,
	Heading,
	VStack,
	ScrollView,
	Center,
} from "native-base";
import Container from "./Container";
import EmbeddedDocumentModal from "../components/EmbeddedDocumentModal";

const PodcastsFaqScreen = ({ navigation }) => {
	return <Container heading="Podcasts &amp;" subheading="FAQ">
		<ScrollView>
			<Center>
				<EmbeddedDocumentModal
					title="FAQs"
					url="https://docs.google.com/document/d/e/2PACX-1vQwPo-Yv7x4ntNAIzfXwX2-rZsQmx2QwmYaOnF3N5hYhcIXS0dn10bIQHKs-XN3HA/pub"
				/>
				<EmbeddedDocumentModal
					title="Podcasts"
					url="https://docs.google.com/document/d/e/2PACX-1vTPjlOcAkLc3A3elGj2-NhetaPwEHoYkoZi7GejD3YgNIdNdRCClRYYdLN0rLTVYAVi1xWy95FkB6l0/pub"
				/>
			</Center>
		</ScrollView>
	</Container>;
};

export default PodcastsFaqScreen;
