import React from "react";
import {
	Box,
	Center,
	VStack,
	Heading
} from "native-base";

export default class Container extends React.Component
{
	render()
	{
		return (
			<Center flex={1}>
				<VStack flex={1} justifyContent="center" maxW="96">
					<Box p="2" mx="auto" minWidth="100%">
						<Heading ml="-2" size="md" fontWeight="medium" color="coolGray.800">
							{this.props.heading}
						</Heading>
						<Heading
							ml="-2"
							mt="1"
							mb="4"
							color="coolGray.600"
							fontWeight="600"
							size="lg"
						>
							{this.props.subheading}
						</Heading>
						{this.props.children}
					</Box>
				</VStack>
			</Center>
		);
	}
}