import * as React from "react";
import {
	Box,
	VStack,
	FormControl,
	Input,
	Button,
	ScrollView,
	Center,
} from "native-base";
import EmbeddedDocumentModal from "../components/EmbeddedDocumentModal";
import Container from "./Container";

import db from "../database/database";
import WithRecordsAndChart from "./WithRecordsAndChart";
import RecordLineChart from "../components/RecordLineChart";
import { RECORD_TYPE_WHITE_BLOOD_CELLS, RECORD_TYPE_NEUTROPHILS, RECORD_TYPE_PLATELETS, RECORD_TYPE_HAEMOGLOBIN } from "../database/RecordType";

export default class MyBloodsScreen extends WithRecordsAndChart
{
	constructor()
	{
		super();

		this.state = {
			[RECORD_TYPE_WHITE_BLOOD_CELLS]:	null,
			[RECORD_TYPE_NEUTROPHILS]:			null,
			[RECORD_TYPE_PLATELETS]:			null,
			[RECORD_TYPE_HAEMOGLOBIN]:			null
		};
	}

	onSubmit(event)
	{
		const user		= this.context.user;
		const promises	= [
			RECORD_TYPE_WHITE_BLOOD_CELLS, 
			RECORD_TYPE_NEUTROPHILS, 
			RECORD_TYPE_PLATELETS, 
			RECORD_TYPE_HAEMOGLOBIN
		].map(key => {
			db
				.records
				.add({
					type:		key,
					points:		parseInt( this.state[key] ),	// NB: Important to cast this or the chart doesn't read it correctly
					timestamp:	new Date(),
					idusers:	user.id
				});
		});

		Promise.all(promises).then(() => this.forceUpdate());
	}

	render()
	{
		return (
			<Container heading="My" subheading="Bloods">
				<ScrollView height="80vh">
					<Center>
						<EmbeddedDocumentModal
							title="Bloods Information"
							url="https://docs.google.com/document/d/e/2PACX-1vQ11guIDD82Ah4Fdpa0GT-wPSPxKaxd8OtCKJh3MpE-j_Ri2cms8KThYc5SokAgdw/pub"
						/>
						<VStack space={3} mt="5" width="311">
							<FormControl>
								<FormControl.Label>WBC (g/L)</FormControl.Label>
								<Input
									type="number"
									placeholder="0.0 (g/L)"
									aria-label="WBC"
									onChangeText={(value) =>
										this.setState({
											[RECORD_TYPE_WHITE_BLOOD_CELLS]: value,
										})
									}
								/>
							</FormControl>
							<FormControl>
								<FormControl.Label>Neutrophils (g/L)</FormControl.Label>
								<Input
									type="number"
									placeholder="0.0 (g/L)"
									aria-label="Neutrophils"
									onChangeText={(value) =>
										this.setState({ [RECORD_TYPE_NEUTROPHILS]: value })
									}
								/>
							</FormControl>
							<FormControl>
								<FormControl.Label>Platelets (g/L)</FormControl.Label>
								<Input
									type="number"
									placeholder="0 (g/L)"
									aria-label="Platelets"
									onChangeText={(value) =>
										this.setState({ [RECORD_TYPE_PLATELETS]: value })
									}
								/>
							</FormControl>
							<FormControl>
								<FormControl.Label>Hb (g/L)</FormControl.Label>
								<Input
									type="number"
									placeholder="0 (g/L)"
									aria-label="Hb"
									onChangeText={(value) =>
										this.setState({ [RECORD_TYPE_HAEMOGLOBIN]: value })
									}
								/>
							</FormControl>
							<Button
								mt="2"
								bg="brand.bmtGreen"
								_text={{
									color: "white",
								}}
								onPress={(event) => this.onSubmit(event)}
							>
								Submit
							</Button>
							<Box height="1/2" minW="50vw">
								<ScrollView>
									<Box width="100%" mt="4">
										<RecordLineChart type={RECORD_TYPE_WHITE_BLOOD_CELLS} />
									</Box>
									<Box width="100%" mt="4">
										<RecordLineChart type={RECORD_TYPE_NEUTROPHILS} />
									</Box>
									<Box width="100%" mt="4">
										<RecordLineChart type={RECORD_TYPE_PLATELETS} />
									</Box>
									<Box width="100%" mt="4">
										<RecordLineChart type={RECORD_TYPE_HAEMOGLOBIN} />
									</Box>
								</ScrollView>
							</Box>
						</VStack>
					</Center>
				</ScrollView>
			</Container>
		);
	}
}