export const RECORD_TYPE_WEIGHT										= "weight";
export const RECORD_TYPE_WHITE_BLOOD_CELLS							= "white-blood-cells";
export const RECORD_TYPE_HAEMOGLOBIN								= "haemoglobin";
export const RECORD_TYPE_PLATELETS									= "platelets";
export const RECORD_TYPE_NEUTROPHILS								= "neutrophils";
export const RECORD_TYPE_FATIGUE									= "fatigue";
export const RECORD_TYPE_ACTIVITY_LEVEL								= "activity-level";
export const RECORD_TYPE_SPPB										= "sppb";
export const RECORD_TYPE_GODIN										= "godin";
export const RECORD_TYPE_1_MIN_STS									= "1-min-sts";
export const RECORD_TYPE_FACIT_F									= "facit-f";
export const RECORD_TYPE_EQ5D5L										= "eq5d5l";
export const RECORD_TYPE_HEALTH_STATE_VISUAL_SCALE					= "health-state-visual-scale";
export const RECORD_TYPE_SIX_MINUTE_WALK_TEST_TIME_ELAPSED			= "six-minute-walk-test-time-elapsed";
export const RECORD_TYPE_SIX_MINUTE_WALK_TEST_DISTANCE_TRAVELLED	= "six-minute-walk-test-distance-travelled";

// TODO: And many more...