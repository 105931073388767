import React from "react";
import {
	Box,
	Heading,
	VStack,
	FormControl,
	Input,
	Button,
	ScrollView,
	Center
} from "native-base";
import Container from "./Container";

import WithRecordsAndChart from "./WithRecordsAndChart";
import { RECORD_TYPE_WEIGHT } from "../database/RecordType";
import RecordLineChart from "../components/RecordLineChart";

export default class MyWeightScreen extends WithRecordsAndChart
{
	get recordType()
	{
		return RECORD_TYPE_WEIGHT;
	}

	render()
	{
		return <Container>
			<Heading ml="2" size="md" fontWeight="medium" color="coolGray.800">
				My
			</Heading>
			<Heading
				ml="2"
				mt="1"
				mb="4"
				color="coolGray.600"
				fontWeight="600"
				size="lg"
			>
				Weight
			</Heading>
			<ScrollView>
				<Center>
					<VStack width="90%" mx="3" maxW="300px" alignItems="center">
						<FormControl isRequired alignItems="center">
							<FormControl.Label
								_text={{
									bold: true,
								}}
							>
								Your current weight in Kg
							</FormControl.Label>
							<Input
								width="25%"
								height="50px"
								_text={{
									fontSize: "2xl",
								}}
								placeholder="KG"
								onChangeText={value => this.onValueChanged(value)}
							/>
						</FormControl>
						<Button
							onPress={() => this.onSubmit()}
							bg="brand.bmtBlue"
							_text={{
								color: "white",
							}}
							mt="4"
							p="4"
							rounded="8"
							shadow={2}
						>
							Submit
						</Button>
					</VStack>
					<Box width="100%" mt="4">
						<RecordLineChart type={this.recordType}/>
					</Box>
				</Center>
			</ScrollView>
		</Container>;
	}
}

