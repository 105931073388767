import * as React from "react";
import {
	Box,
	Heading,
	VStack,
	ScrollView,
	Center,
} from "native-base";
import Container from "./Container";
import EmbeddedDocumentModal from "../components/EmbeddedDocumentModal";

const HowToMonitorMyProgressScreen = ({ navigation }) => {
	return <Container heading="What can I do to monitor" subheading="my progress?">
		<ScrollView>
			<Center>
				<EmbeddedDocumentModal
					title="Pre transplant"
					url="https://docs.google.com/document/d/e/2PACX-1vRQPda7iqARXs5GrQMf14ZB6rZ6XDyNclSztXCAqeq2o1GJ47C_3JiqRh1l0w7Bpg/pub"
				/>
				<EmbeddedDocumentModal
					title="My admission"
					url="https://docs.google.com/document/d/e/2PACX-1vSSYvbS-IuGFNN4WX0TTVGOPu4G1b5ZXHACOR8ipot5TY3XzY9xxib8NZmK1zY8pQ/pub"
				/>
				<EmbeddedDocumentModal
					title="Post Transplant"
					url="https://docs.google.com/document/d/e/2PACX-1vR4_dRWmYzcmEHdDamk0gNFxdwwEbi0jqFBadzUPqQhuFRu1bqUfmyNJNiBzKkdZQ/pub"
				/>
			</Center>
		</ScrollView>
	</Container>;
};

export default HowToMonitorMyProgressScreen;
