import * as React from "react";
import {
	Box,
	Heading,
	VStack,
	ScrollView,
	Center,
} from "native-base";
import Container from "./Container";
import EmbeddedDocumentModal from "../components/EmbeddedDocumentModal";

const PrehabilitationScreen = ({ navigation }) => {
	return <Container heading="Prehabilitation" subheading="reading">
		<ScrollView>
			<Center>
				<EmbeddedDocumentModal
					title="Intro to Psychology"
					url="https://docs.google.com/document/d/e/2PACX-1vQ8c4WHQ2FRmq3rOT9xMwX2GoFAv7Rwvb62hvr8EWq9wStgfo_iFUBl8bEI4CByrw/pub"
				/>
				<EmbeddedDocumentModal
					title="Physio in prehab in BMT"
					url="https://docs.google.com/document/d/e/2PACX-1vR0TUP5bzXZ2YTQmLCVEkMVsmEmCBftneDtpgDebVcqPvSjYmj_irNpmxzm9fL9hg/pub"
				/>
				<EmbeddedDocumentModal
					title="Psychological Preparation for treatment"
					url="https://docs.google.com/document/d/e/2PACX-1vS7qZu8bF8XJt0LYT1LJVdZnM8taZ206jcFDMcr4-MFThqF93Fk1zun0cy6qptKag/pub"
				/>
				<EmbeddedDocumentModal
					title="Role of physiotherapy with BMT patients"
					url="https://docs.google.com/document/d/e/2PACX-1vR6pQ1HzYoSh8NUcWEtaUmg2h5DG1c-UDaYnsQDFVv1Jd3Z2HQCGDKTs2xUm_xG5jIoIKzIN3TciPTI/pub"
					
				/>
			</Center>
		</ScrollView>
	</Container>;
};

export default PrehabilitationScreen;
