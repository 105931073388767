import 'raf/polyfill';
import React, { useState, useEffect } from "react";
import {
	Box,
	Text,
	Heading,
	VStack,
	FormControl,
	Input,
	Link,
	Button,
	HStack,
	Center,
	NativeBaseProvider,
  extendTheme,
} from "native-base";
import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import UserContext from "./contexts/UserContext";
import User from "./User";

import AppLoading from "expo-app-loading";
import * as Font from "expo-font";

import Faker from "./database/Faker";
import DrawerNavigator from './navigation/DrawerNavigator';
import "./App.css"

//console.warn = () => {}; // TODO: Horrible, please remove me

// Fetch Fonts
const fetchFonts = () => {
  return Font.loadAsync({
    "roboto-black": require("./assets/fonts/Roboto-Black.ttf"),
    "roboto-regular": require("./assets/fonts/Roboto-Regular.ttf")
  })
}

// extend the theme
const newColorTheme = {
	brand: {
		bmtGreen: "#73BD89",
		bmtDarkGreen: "#5A926A",
		bmtRed: "#D11444",
		bmtGray: "#4D4D45",
		bmtLightGray: "#D9D9D9",
		bmtBlue: "#1B979A",
		bmtYellow: "#D3D933",
	},
	components: {
		Input: {
			baseStyle: {
				fontSize: "2xl",
			},
		},
	},
};
const theme = extendTheme({ colors: newColorTheme });

export default function App() {
  const [dataLoaded, setDataLoaded] = useState();

  const [user, setUser] = useState(new User());
  const userContextProviderValue = { user, setUser };

  /*useEffect(() => {
	const faker = new Faker();
	faker.generate(7);
  }, []);*/

  if(!dataLoaded) {
    return <AppLoading startAsync={fetchFonts} onFinish={() => setDataLoaded(true)} onError={(err) => console.log(err)} />;
  }

  return (
		<NativeBaseProvider theme={theme}>
			<UserContext.Provider value={userContextProviderValue}>
				<NavigationContainer>
					<DrawerNavigator />
				</NavigationContainer>
			</UserContext.Provider>
		</NativeBaseProvider>
	);
}
