import * as React from "react";
import {
	Box,
	Text,
	Heading,
	VStack,
	Pressable,
	ScrollView,
	Center,
} from "native-base";
import Container from "./Container";
import EmbeddedDocumentModal from "../components/EmbeddedDocumentModal";

const PhysicalActivityMonitoringScreen = ({ navigation }) => {
	return <Container heading="My physical activity" subheading="monitoring tools">
		<ScrollView height="80vh">
			<Center>
				<EmbeddedDocumentModal
					title="Information about monitoring tools"
					url="https://docs.google.com/document/d/e/2PACX-1vT8ibyeQxPO0aNtE9vhuHUL3K8etOWkk5ncHTr5T4MZQHfaw2WtJdgVFqS5fegRzQ/pub"
				/>
				<Pressable onPress={() => navigation.navigate("SPPBScreen")}>
					{({ isHovered, isFocused, isPressed }) => {
						return (
							<Box
								width="311"
								mt="4"
								borderWidth="1"
								borderColor="coolGray.300"
								shadow="3"
								bg="brand.bmtGreen"
								p="5"
								rounded="8"
								style={{
									transform: [
										{
											scale: isPressed ? 0.96 : 1,
										},
									],
								}}
							>
								<Text color="#fff" fontWeight="medium" fontSize="xl">
									SPPB
								</Text>
								<Text
									color="coolGray.200"
									fontWeight="light"
									fontSize="sm"
								>
									Short Performance Physical Battery is a measure of your
									strength, balance &amp; walking. Please watch the video to
									help you complete this.
								</Text>
							</Box>
						);
					}}
				</Pressable>
				<Pressable onPress={() => navigation.navigate("SixMinuteWalkTest")}>
					{({ isHovered, isFocused, isPressed }) => {
						return (
							<Box
								width="311"
								mt="4"
								borderWidth="1"
								borderColor="coolGray.300"
								shadow="3"
								bg="brand.bmtYellow"
								p="5"
								rounded="8"
								style={{
									transform: [
										{
											scale: isPressed ? 0.96 : 1,
										},
									],
								}}
							>
								<Text color="#fff" fontWeight="medium" fontSize="xl">
									6MWT
								</Text>
								<Text
									color="coolGray.500"
									fontWeight="light"
									fontSize="sm"
								>
									6 minute walk test. A measure of your aerobic exercise capacity.
								</Text>
							</Box>
						);
					}}
				</Pressable>
				<Pressable
					onPress={() => navigation.navigate("FACITFQuestionnaire")}
				>
					{({ isHovered, isFocused, isPressed }) => {
						return (
							<Box
								width="311"
								mt="4"
								borderWidth="1"
								borderColor="coolGray.300"
								shadow="3"
								bg="brand.bmtDarkGreen"
								p="5"
								rounded="8"
								style={{
									transform: [
										{
											scale: isPressed ? 0.96 : 1,
										},
									],
								}}
							>
								<Text color="#fff" fontWeight="medium" fontSize="xl">
									FACIT-F
								</Text>
								<Text
									color="coolGray.200"
									fontWeight="light"
									fontSize="sm"
								>
									A questionnaire to show quality of life concerns related
									to fatigue in cancer patients
								</Text>
							</Box>
						);
					}}
				</Pressable>
				<Pressable
					onPress={() => navigation.navigate("EQ5D5LQuestionnaireScreen")}
				>
					{({ isHovered, isFocused, isPressed }) => {
						return (
							<Box
								width="311"
								mt="4"
								borderWidth="1"
								borderColor="coolGray.300"
								shadow="3"
								bg="brand.bmtBlue"
								p="5"
								rounded="8"
								style={{
									transform: [
										{
											scale: isPressed ? 0.96 : 1,
										},
									],
								}}
							>
								<Text color="#fff" fontWeight="medium" fontSize="xl">
									EQ-5D-5L
								</Text>
								<Text
									color="coolGray.200"
									fontWeight="light"
									fontSize="sm"
								>
									A questionnaire to demonstrate your health related
									quality of life
								</Text>
							</Box>
						);
					}}
				</Pressable>
				<Pressable onPress={() => navigation.navigate("STS")}>
					{({ isHovered, isFocused, isPressed }) => {
						return (
							<Box
								width="311"
								mt="4"
								borderWidth="1"
								borderColor="coolGray.300"
								shadow="3"
								bg="brand.bmtGreen"
								p="5"
								rounded="8"
								style={{
									transform: [
										{
											scale: isPressed ? 0.96 : 1,
										},
									],
								}}
							>
								<Text color="#fff" fontWeight="medium" fontSize="xl">
									1 Minute sit to stand
								</Text>
								<Text
									color="coolGray.200"
									fontWeight="light"
									fontSize="sm"
								>
									Complete as many full sit to stands from the chair as you can in 1 minute.
								</Text>
							</Box>
						);
					}}
				</Pressable>
			</Center>
		</ScrollView>
	</Container>;
};

export default PhysicalActivityMonitoringScreen;
