import { createStackNavigator } from "@react-navigation/stack";
import HowToMonitorMyProgressScreen from "../screens/HowToMonitorMyProgressScreen";
import MyBloodsScreen from "../screens/MyBloodsScreen";
import defaultScreenOptions from "./DefaultOptions";
import MyProgressScreen from "../screens/MyProgressScreen";
import PostTransplantMonitoringStack from "./PostTransplantMonitoringStack";
import PhysicalActivityStack from "./PhysicalActivityStack";

const Stack = createStackNavigator();

export default function ProgressStack() {
  return (
    <Stack.Navigator
      initialRouteName="MyProgress"
      screenOptions={{ headerShown: false, title: "Physical Activity" }}
    >
      <Stack.Screen name="MyProgress" component={MyProgressScreen} />
      <Stack.Screen
        name="HowToMonitorProgress"
        component={HowToMonitorMyProgressScreen}
        options={defaultScreenOptions}
      />
      <Stack.Screen
        name="Bloods"
        component={MyBloodsScreen}
        options={defaultScreenOptions}
      />
      <Stack.Screen
        name="PostTransplantMonitoringStack"
        component={PostTransplantMonitoringStack}
        options={defaultScreenOptions}
      />
      <Stack.Screen
        name="PhysicalActivityMonitoring"
        component={PhysicalActivityStack}
        options={defaultScreenOptions}
      />
    </Stack.Navigator>
  );
}
