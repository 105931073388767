import React, {useContext, useState, useEffect} from "react";
import {
	Center,
	Box,
	Text,
	Button,
	VStack,
	FormControl,
	Input,
	Checkbox,
	Divider,
	Image,
	Link,
	View
} from "native-base";
import UserContext from "../contexts/UserContext";
import UserNotFoundError from "../exceptions/UserNotFoundError";
import IncorrectPasswordError from "../exceptions/IncorrectPasswordError";
import User from "../User";
import UserAlreadyExistsError from "../exceptions/UserAlreadyExistsError";

const Logo = require("../assets/images/bmt-connect-logo-reverse-rgb-540px@72ppi.png");

const LoginScreen = ({ navigation }) => {
	const { user, setUser } = useContext(UserContext);

	const initalFormData = { email: ""};
	const [ formData, setFormData ] = useState(initalFormData);
	const [ errors, setErrors ] = useState({});
	const [ isSignUp, setIsSignUp ] = useState(false);

	useEffect(() => {
		if (user && user.reset) {
			// reset the form - user has logged out
			setErrors({});
			resetForm();
			setUser(new User());
		}
	}, [user])

	const resetForm = () => setFormData(initalFormData)

	const onLogin = async (event) => {
		setErrors({});

		try {
			const foundUser = await user.login(formData.email);
			setUser(new User(foundUser.id)); // reinitialise the user so that the context gets updated
			// navigation.navigate("IntroScreen");

		} catch(error) {
			if (error instanceof UserNotFoundError) {
				const error = "Invalid email or username";
				setErrors({...errors, email: error});
				return;
			}
			else {
				setErrors({...errors, generic: "Sorry something went wrong"});
				throw error; // NB: Rethrow, this error was not expected
			}
		}
	};

	const onSignUp = async () => {
		setErrors({});

		if (!formData.email) {
			setErrors({...errors, email: "Please fill in the email field."});
		} else {
			try {
				const userId = await user.create(formData.email);
				setUser(new User(userId));				
			} catch (error) {
				if (error instanceof UserAlreadyExistsError) {
					setErrors({...errors, email: "This user already exists. Try logging in instead."});
				} else {
					setErrors({...errors, generic: "Sorry, something went wrong creating your account."});
					throw error;
				}
			}
		}
	}

	const handleSubmit = () => {
		if (isSignUp)
			onSignUp();
		else
			onLogin();
	}


const styles = {
	center: {
	  flex: 1,
	  bg: "brand.bmtGreen",
	},
	vStack: {
	  flex: 1,
	  bg: "brand.bmtGreen",
	  w: "90%",
	  maxW: "290",
	  justifyContent: "flex-end",
	  display:"flex",
	  minH:"100vh",
	  space:25
	},
	image: {
	  size: "sm",
	  mb: "6",
	  width: "100%",
	  height: "120px",
	},
	text: {
	  fontWeight: "medium",
	  fontSize: "28",
	  mb: 4,
	  color: "white",
	},
	inputBox: {
	  mb: 4,
	},
	input: {
	  placeholderTextColor: "gray.300",
	  size: "xl",
	  variant: "filled",
	  bg: "#70AD82",
	},
	errorText: {
	  color: "error.500",
	},
	linkText: {
	  fontSize: "xs",
	  fontWeight: "500",
	  color: "white",
	},
	vStackWithMargin: {
	  mt: "4",
	  justifyContent: "center",
	  alignItems: "center",
	  marginBottom: "4",
	},
	link: {
	  _text: {
		color: "white",
		fontWeight: "medium",
		fontSize: "sm",
	  },
	},
	box: {
	  borderTopLeftRadius: "10",
	  borderTopRightRadius: "10",
	  background: "brand.bmtRed",
	  p: "2",
	  py: "8",
	  width: "full",
	},
	checkbox: {
	  justifyContent: "center",
	  mb: 4,
	  shadow: 2,
	  accessibilityLabel: "terms & conditions checkbox",
	  value: "danger",
	  colorScheme: "danger",
	},
	divider: {
	  my: 4,
	  bg: "#9B3146",
	},
	button: {
	  mt: "2",
	  background: "transparent",
	},
	redLogin:{
		justifyItems: "flex-end",
		/*position: "absolute",
		bottom: 0,*/
		width: "full"
	}
  };

	return (
		<Center {...styles.center}>
		<VStack {...styles.vStack}>
		  <Image source={Logo} alt="icon" {...styles.image} />
		  <Text {...styles.text}>
			{isSignUp ? "Sign up to BMT Connect" : "Hi there, please log in"}
		  </Text>
  
		  <FormControl>
			<Box {...styles.inputBox}>
			  <Input
				placeholder="Email"
				type="email"
				value={formData.email}
				onChangeText={(value) =>
				  setFormData({ ...formData, email: value })
				}
				{...styles.input}
			  />
			  {"email" in errors && (
				<Text {...styles.errorText}>{errors.email}</Text>
			  )}
			</Box>
			{errors.generic && (
			  <Text {...styles.errorText}>{errors.generic}</Text>
			)}
		  </FormControl>
		
		<View {...styles.redLogin}>
		  <VStack {...styles.vStackWithMargin}>
			<Text
			  fontSize="sm"
			  color="coolGray.600"
			  _dark={{
				color: "warmGray.200",
			  }}
			>
			  {isSignUp ? "Already got an account? " : "Need an account? "}
			</Text>
			<Link
			  href="#"
			  onPress={() => {
				setIsSignUp(!isSignUp);
				setFormData(initialFormData);
				setErrors({});
			  }}
			  {...styles.link}
			>
			  {isSignUp ? "Log In" : "Sign Up"}
			</Link>
		  </VStack>
		  <VStack space={6}  width="full">
			<Box {...styles.box}>
			  {isSignUp && (
				<>
				  <Checkbox {...styles.checkbox}>Terms &amp; conditions</Checkbox>
				  <Divider {...styles.divider} />
				</>
			  )}
			  <Button onPress={handleSubmit} {...styles.button}>
				{isSignUp ? "Sign up" : "Login"}
			  </Button>
			</Box>
		  </VStack>
		  </View>
		</VStack>

	  </Center>
	);
};

export default LoginScreen;
