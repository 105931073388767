import * as React from "react";
import {
	Box,
	Heading,
	VStack,
	Center,
} from "native-base";
import EmbeddedDocument from "../components/EmbeddedDocument";

export default function PrehabInBmtScreen({ navigation }) {
	return (
		<Center flex={1}>
			<VStack flex={1} justifyContent="center" maxW="96">
				<Box p="4" mx="auto" minW="96" mt="24">
					<Heading ml="-2" size="md" fontWeight="medium" color="coolGray.800">
						What is
					</Heading>
					<Heading
						ml="-2"
						mt="1"
						mb="4"
						color="coolGray.600"
						fontWeight="600"
						size="lg"
					>
						Prehab?
					</Heading>
				</Box>
				<Box>
					<EmbeddedDocument url="https://docs.google.com/document/d/e/2PACX-1vTh8_dczv4BB16ox_UXjZglwuhO07SyFnfLRv17lZSHcEDASGYN1LkvHpbkR8ZoMA/pub"/>
				</Box>
			</VStack>
		</Center>
	);
}
