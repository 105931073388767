import React from "react";
import { Text } from "native-base";
import { DateTime } from "luxon";
import UserContext from "../contexts/UserContext";
import LineChart from "./LineChart";
import RecordTypeUnits from "../database/RecordTypeUnits";
import db from "../database/database";

class LastReadingText extends React.Component 
{
	render() {
		return (
			<>
				<Text fontWeight="bold">
					Last reading: {this.props.lastEntry.points}
					{" " + this.props.units}
				</Text>
				<Text color="#A3A3A3">
					(recorded {DateTime.fromJSDate(this.props.lastEntry.timestamp).toRelativeCalendar()})
				</Text>
			</>
		)
	}
}

export default class RecordLineChart extends React.Component
{
	static contextType = UserContext;

	constructor()
	{
		super();

		this.state = {
			chartData: []
		};
	}

	componentDidMount()
	{
		this.updateChartData();
	}

	componentDidUpdate()
	{
		this.updateChartData();
	}

	getRecords(type)
	{
		const user = this.context.user;

		return db
			.records
			.where("idusers")
			.equals(user.id)
			.and(record => record.type == type)
			.toArray();
	}

	updateChartData()
	{
		if(!("type" in this.props))
			throw new Error("RecordLineChart component requires record type in props");
		
		this
			.getRecords(this.props.type)
			.then((records) => {
				this.setState({
					chartData: records.map(record => { return {points: record.points, timestamp: record.timestamp}; })
				});
			});
	}

	render()
	{
		const { type } = this.props;
		const caption = type.replace(/-/g, " ").replace(/(^|\s)\S/g, function(t) { return t.toUpperCase() });

		return <>
			<Text fontSize="md" style={{ color: "#73BD89" }}>
				{caption}
			</Text>
			{this.state.chartData.length >= 2 && (
				<LineChart 
					data={this.state.chartData} 
					units={RecordTypeUnits[type]}
				/>
			)}
			{this.state.chartData.length >= 1 && (
				<LastReadingText 
					lastEntry={this.state.chartData[this.state.chartData.length - 1]} 
					units={RecordTypeUnits[type]}
				/>
			)}
			{this.state.chartData.length === 0 && (
				<Text color="#A3A3A3">Please enter your results.</Text>
			)}
			
		</>;
	}
}