import React from "react";
import {
	Box,
	Heading,
	Stack,
	VStack,
	HStack,
	Button,
	ScrollView,
	Center,
	Image,
	Text
} from "native-base";
import Container from "./Container";
import WithTimer from "../../screens/WithTimer";
import Stopwatch from "./Stopwatch";

export default class SPPBExercise extends WithTimer
{
	onProceed()
	{
		const args = {
			points: this.props.getPointsFromMilliseconds(this.state.elapsed)
		};

		if(this.props.skipToStageIfUnderTenSeconds && this.state.elapsed < 10 * 1000)
			args.skipToStageIndex = this.props.skipToStageIfUnderTenSeconds;

		this.props.onProceed(args);
	}

	render()
	{
		if(!("image" in this.props))
			throw new Error("Props is missing image");

		return <Container>
			<Heading ml="2" size="md" fontWeight="medium" color="coolGray.800">
				SPPB
			</Heading>
			<Heading
				ml="2"
				mt="1"
				mb="4"
				color="coolGray.600"
				fontWeight="600"
				size="lg"
			>
				{this.props.subheading}
			</Heading>
			<ScrollView>
				<Center>
					<VStack width="90%" mx="3" maxW="300px" alignItems="center">
						<Center>
							<Text>
								{this.props.description}
							</Text>

							<Box bg="white" shadow={2} rounded="lg" maxWidth="90%">
								<Image
									source={this.props.image}
									alt="image base"
									resizeMode="cover"
									height={150}
									roundedTop="md"
									width={150}
								/>
								<Stack space={4} p={[4, 4, 8]}>
									<Heading size={["md", "lg", "md"]}>{this.props.title}</Heading>
								</Stack>
							</Box>
						</Center>
						<Stopwatch
							elapsed={this.state.elapsed}
							isTimerRunning={this.state.isTimerRunning}
							onStart={() => this.onStart()}
							onStop={() => this.onStop()}
							onReset={() => this.onReset()}
						/>
						{
							<Button
								onPress={() => this.onProceed()}
								bg="brand.bmtBlue"
								_text={{
									color: "white",
								}}
								mt="4"
								p="4"
								rounded="8"
								shadow={2}
							>
								{
									this.state.elapsed > 0 && !this.state.isTimerRunning ?
									"Submit" :
									"Skip"
								}
							</Button>
						}
					</VStack>
				</Center>
			</ScrollView>
		</Container>;
	}
}