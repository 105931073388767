import * as React from "react";
import {
	Box,
	Text,
	Heading,
	VStack,
	FormControl,
	Input,
	Button,
	ScrollView,
	Center,
} from "native-base";
import {
	StyleSheet,
	View,
	TouchableHighlight,
} from "react-native";
import { Duration } from "luxon";

import WithTimer from "./WithTimer";
import StackedRecordLineChart from "../components/StackedRecordLineChart";
import { RECORD_TYPE_SIX_MINUTE_WALK_TEST_DISTANCE_TRAVELLED, RECORD_TYPE_SIX_MINUTE_WALK_TEST_TIME_ELAPSED } from "../database/RecordType";

const TIMER_LIMIT = 6 * 60 * 1000;

export default class SixMinuteWalkTestScreen extends WithTimer
{
	onAnimationFrame()
	{
		const sixMinutes = TIMER_LIMIT;

		super.onAnimationFrame();

		if(this.state.isTimerRunning && this.state.elapsed >= TIMER_LIMIT)
			this.setState({
				isTimerRunning: false,
				elapsed: sixMinutes
			});
	}

	onDistanceChanged(value)
	{
		this.setState({
			distance: value
		});
	}

	onSubmit()
	{
		this.submitRecord(RECORD_TYPE_SIX_MINUTE_WALK_TEST_DISTANCE_TRAVELLED, this.state.distance).then(() => console.log("Done"));
		this.submitRecord(RECORD_TYPE_SIX_MINUTE_WALK_TEST_TIME_ELAPSED, this.state.elapsed);
	}

	render()
	{
		const duration	= Duration.fromMillis(TIMER_LIMIT - this.state.elapsed);
		const clockText	= duration.toFormat("hh:mm:ss:SSS");

		return (
			<Center flex={1}>
				<VStack flex={1} justifyContent="center" maxW="96">
					<Box p="2" mx="auto" minWidth="100%">
						<Heading ml="2" size="md" fontWeight="medium" color="coolGray.800">
							6 min
						</Heading>
						<Heading
							ml="2"
							mt="1"
							mb="4"
							color="coolGray.600"
							fontWeight="600"
							size="lg"
						>
							Walk Test
						</Heading>
						<ScrollView>
							<Center>
								<VStack width="90%" mx="3" maxW="300px" alignItems="center">
									<Center>
										<Heading size="xs" fontWeight="500" ml={-0.5} mb={2} mt={2}>
											Measure a 10m stretch in your home or garden, if you cannot get 10m in a straight or circle you may use 5m track.
										</Heading>
										<Text>
											The aim of the test is to walk as far as you can in 6 minutes, if you need to stop and rest you may do so.
										</Text>
										<Text>
											Please remember the objective is to walk as far as you can in 6 minutes but do not jog/run.
										</Text>
										<Text>
											If you cannot continue with the test please stop and record the time you stopped the test.
										</Text>
										<Center>
											<View style={styles.sectionStyle}>
												<Box>
													<Text>
														{clockText}
													</Text>
												</Box>
												<TouchableHighlight
													onPress={() => { return this.state.isTimerRunning ? this.onStop() : this.onStart() }}
												>
													<Text style={styles.buttonStart}>
														{!this.state.isTimerRunning ? "START" : "STOP"}
													</Text>
												</TouchableHighlight>
												<TouchableHighlight
													onPress={() => this.onReset()}
												>
													<Text style={styles.buttonReset}>RESET</Text>
												</TouchableHighlight>
											</View>
											{
												this.state.elapsed > 0 && !this.state.isTimerRunning ? <>
													<FormControl isRequired alignItems="center">
														<FormControl.Label
															_text={{
																bold: true,
															}}
														>
															Distance travelled
														</FormControl.Label>
														<Input
															width="25%"
															height="50px"
															_text={{
																fontSize: "2xl",
															}}
															placeholder="meters"
															onChangeText={value => this.onDistanceChanged(value)}
														/>
													</FormControl>
													<Button
														onPress={() => this.onSubmit()}
														bg="brand.bmtBlue"
														_text={{
															color: "white",
														}}
														mt="4"
														p="4"
														rounded="8"
														shadow={2}
													>
														Submit
													</Button>
												</>
												: null
											}
										</Center>
									</Center>
								</VStack>
								<Box width="100%" mt="4">
									<StackedRecordLineChart 
										caption="Distance and Time"
										types={[RECORD_TYPE_SIX_MINUTE_WALK_TEST_DISTANCE_TRAVELLED, RECORD_TYPE_SIX_MINUTE_WALK_TEST_TIME_ELAPSED]} 
										normalize={true}
									/>
								</Box>
							</Center>
						</ScrollView>
					</Box>
				</VStack>
			</Center>
		);
	}
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		padding: 10,
		justifyContent: "center",
		alignItems: "center",
	},
	sectionStyle: {
		flex: 1,
		marginTop: 32,
		alignItems: "center",
		justifyContent: "center",
	},
	buttonStart: {
		fontSize: 20,
		marginTop: 20,
        padding: 10,
		borderRadius: 30,
		backgroundColor: "#73BD89",
        color: "white",
	},
	buttonReset: {
		fontSize: 20,
		marginTop: 10,
        borderRadius: 30,
        padding: 10,
        backgroundColor: "#FF6B6B",
        color: "white",
	},
});

const options = {
	container: {
		padding: 5,
		borderRadius: 10,
		borderWidth: 5,
		width: 300,
		alignItems: "center",
	},
	text: {
		fontSize: 30,
		marginLeft: 7,
	},
};