import * as React from "react";
import {
	Box,
	Heading,
	VStack,
	ScrollView,
	Center,
} from "native-base";
import EmbeddedDocumentModal from "../components/EmbeddedDocumentModal";
import Container from "./Container";

const DietNutritionScreen = ({ navigation }) => {
	return (
		<Container heading="Diet &amp;" subheading="Nutrition">
			<ScrollView>
				<Center>
					<EmbeddedDocumentModal
						title="Diet Myths"
						url="https://docs.google.com/document/d/e/2PACX-1vT-BiD_CasNTMkFDprQt1TGeiwN9GM_BYifKiqdouPmRcGD3C2hoAb2meuA0gpZ5cDnfb_z4WSr7yAI/pub"
					/>
					<EmbeddedDocumentModal
						title="Healthy Eating"
						url="https://docs.google.com/document/d/e/2PACX-1vTYFA0QehjDa5wiIGWk3CKXPxbq7iZ3LMf9DM_mymXmUCXQDDhMBQ6K4ZMKAipbdg/pub"
					/>
					<EmbeddedDocumentModal
						title="Nourishing Diet"
						url="https://docs.google.com/document/d/e/2PACX-1vRz7VkEw71DJU32u907-k6bSwD4z9XEuDGYgak6ShB2kjxWDHuNdF4x7m6XtLLBOQ/pub"
					/>
					<EmbeddedDocumentModal
						title="Trusted Websites"
						url="https://docs.google.com/document/d/e/2PACX-1vSQGGJ4zDHcoVzHdP0oL9m7X_WnVVQ61oO2ZugF7mLuXMify4zOd7oMyBqoYn6xzw/pub"
					/>
				</Center>
			</ScrollView>
		</Container>
	);
};

export default DietNutritionScreen;
