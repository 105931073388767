import React from "react";
import { Platform } from "react-native";

export default class EmbeddedDocument extends React.Component
{
	render()
	{
		return Platform.OS === "web" ? (
			<iframe
				src={this.props.url + "?rm=minimal"}
				height={500}
				width="100%"
			/>
		) : (
			<WebView
				style={{ marginBottom: 100 }}
				originWhitelist={["*"]}
				source={{
					uri: this.props.url,
				}}
			/>
		);
	}
}