import * as React from "react";
import {
	Box,
	Heading,
	VStack,
	ScrollView,
	Center,
} from "native-base";
import Container from "./Container";
import EmbeddedDocumentModal from "../components/EmbeddedDocumentModal";

const PostTransplantDietNutritionScreen = ({ navigation }) => {
	return <Container heading="Diet &amp;" subheading="Nutrition">
		<ScrollView>
			<Center>
				<EmbeddedDocumentModal
					title="Food Safety at Home"
					url="https://docs.google.com/document/d/e/2PACX-1vQ6kNF39gQNr4rFZg6R2VHTM0YHEU5zyscJyN9pVbN7OEqGxwIfdBrX70EKGIcKWA/pub"
				/>
				<EmbeddedDocumentModal
					title="Nutritional Rehab After Transplant"
					url="https://docs.google.com/document/d/e/2PACX-1vT3565-wSEM8ywegiX2Q8P0WHBLL7Xm4pugwl39SvjPbKQN2ZdPRMio3_Z7sYCX1A/pub"
				/>
			</Center>
		</ScrollView>
	</Container>;
};

export default PostTransplantDietNutritionScreen;
